import { Image, Text } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { showModal } from 'components/forms/ModalDialog';
import { IFile } from 'features/files/fileAPI';
import {
  AcceptedMimeType,
  getFileTypeInfo,
  isImageType,
} from 'features/files/fileSlice';

type ImageViewLinkProps = {
  file: IFile;
  noPreview?: boolean;
  className?: string;
};

export function ImageViewLink(props: ImageViewLinkProps & PropsWithChildren) {
  const { file, noPreview = false, className, children } = props;
  const intl = useIntl();
  const ftInfo = getFileTypeInfo(file);
  if (isImageType(ftInfo.mime))
    return (
      <div
        className={className ?? 'msg_file'}
        // color={'red'}
        // style={
        //   children || noPreview
        //     ? undefined
        //     : {
        //         backgroundImage: `url("${file.uri}")`,
        //       }
        // }
        onClick={() => {
          showModal(
            file.description,
            <div
              className="img_view"
              // style={{ backgroundImage: `url("${file.uri}"")` }}
            >
              <img src={file.uri} alt={file.description} />
            </div>,
            {
              intl,
              maxHeight: true,
              maxWidth: true,
              bare: true,
            },
          );
        }}
      >
        {children ? (
          children
        ) : (
          <img src={`${file.uri}`} alt={file.description} />
        )}
      </div>
    );
  else
    return (
      <Link
        to={file.uri ?? ''}
        target="_blank"
        //  download={'file.docx'}
        // the download attribute only works for same-origin, which we don't have because the files come directly from the azure storage (windows.net)
      >
        {children}
      </Link>
    );
}

export function ImageViewLinkToPdf(
  props: ImageViewLinkProps & PropsWithChildren,
) {
  const { file, noPreview = false, className, children } = props;
  const ftInfo = getFileTypeInfo(file);
  if (isImageType(ftInfo.mime)) return <Image src={file.uri} />;
  else return <Text>{file.uri ?? ''}</Text>;
}
