import LinkifyIt from 'linkify-it';
import React, { Fragment, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

const linkify = new LinkifyIt();

// Add a rule to match URLs
// linkify.add('http:', {
//   validate: (text: string, pos: number, self: any) => {
//     const tail = text.slice(pos);
//     if (!self.re.test(tail)) {
//       // URL validation failed
//       return 0;
//     }
//     return tail.match(self.re)![0].length;
//   },
// });

type LinkifyTextProps = {
  // input?: string;
  useText?: boolean; // use the text to represent the link (instead of the link itself)
  Wrapper?: 'span' | 'div' | React.ComponentType<PropsWithChildren>;
};

/*
 * LinkifyText
 */
export default function LinkifyText(
  props: LinkifyTextProps & PropsWithChildren
) {
  const { children, useText = false, Wrapper = React.Fragment } = props;
  if (!children) return null;
  const inputs =
    typeof children === 'string'
      ? children.split(/\r\n|\r|\n/)
      : Array.isArray(children)
      ? children
      : [];
  if (!inputs)
    throw new Error(
      'LinkifyText expects string or array of strings as children but got something else.'
    );
  let fragments: JSX.Element[] = [];
  inputs.forEach((input, ii) => {
    const { texts, links } = linkifyText(input);
    if (!texts.length) return null;
    if (links.length === 0)
      // text only
      fragments.push(<Fragment key={`text_${ii}_0`}> {texts[0]}</Fragment>);
    else {
      for (let i = 0; i < texts.length; i++) {
        let text = texts[i];
        let link: string | null = null;
        if (typeof text === 'number') {
          // link first
          link = links[text];
          text = link;
          // useText && i < texts.length - 1
          //   ? texts[++i] !== ''
          //     ? texts[i]
          //     : link
          //   : link;
        } else {
          // text first
          link =
            useText && i < texts.length - 1
              ? links[texts[++i] as number]
              : null;
        }
        fragments.push(
          link ? (
            <Fragment key={`fragment_${ii}_${i}`}>
              {' '}
              <Link to={link} target="_blank">
                {text}
              </Link>
            </Fragment>
          ) : (
            <Fragment key={`fragment_${ii}_${i}`}> {text}</Fragment>
          )
        );
      }
    }
    if (ii < inputs.length - 1) fragments.push(<br key={`br_${ii}`} />);
  });
  const WrapperComponent = Wrapper as React.ComponentType<PropsWithChildren>;
  return <WrapperComponent>{fragments}</WrapperComponent>;
}

export function linkifyText(input: string): {
  texts: (string | number)[];
  links: string[];
} {
  const texts: (string | number)[] = [];
  const links: string[] = [];
  const matches = linkify.match(input);
  let last = 0;
  if (matches) {
    matches.forEach((match, i) => {
      const { index, lastIndex } = match;
      const text = input.slice(last, index);
      last = lastIndex;
      if (text) texts.push(text.trim());
      texts.push(i);
      links.push(match.url);
    });
    const rest = input.slice(matches[matches.length - 1].lastIndex);
    if (rest) texts.push(rest.trim());
  } else texts.push(input);
  return { texts, links };
}

export function rebuildLinkText(texts: (string | number)[], links: string[]) {
  let link = '';
  texts.forEach((text, i) => {
    if (typeof text === 'string') link += text + ' ';
    else link += links[text] + ' ';
  });
  return link.trim();
}
