import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { navigateIn } from 'components/navigation/Navigation';
import { resetPwdAsync } from 'features/user/userSlice';
import { PASSWORD_PATTERN } from 'helpers/consts';
import { escapeRegEx } from 'helpers/helpers';
import { UserLoginFormLink } from './UserLoginForm';

export default function UserResetPwdForm(props: FormProps) {
  const { show, setShow } = props;
  const refSubmitBtn = useRef(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    email: '',
    password: '',
    password2: '',
    token: '',
  });
  const [hasReset, setHasReset] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);
  const { token } = useParams();

  // we use the token parameter from the url to receive the token (see App.js)
  useEffect(() => {
    if (show) {
      if (!token) {
        setShow(false);
        navigateIn('/', 0);
      } else {
        setData({ ...data, token: token });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, token]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
    setHasReset(false);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setValidated(false);
    if (target.form && target.name === 'password') {
      target.form.password2.pattern = escapeRegEx(target.value);
    }
    if (target.name === 'password2') setValidated(true);
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    if (hasReset) {
      setShow(false);
      navigateIn('/', 0);
      return;
    }
    return new Promise((resolve, reject) => {
      try {
        if (data.password !== data.password2) {
          setValidated(true);
          doFormMsg(
            {
              message: intl.formatMessage({ id: 'USERS.RESET.NOMATCH' }),
              success: false,
            },
            setFormMsg,
          );
          reject();
          return;
        }
        setValidated(false);
        dispatch(resetPwdAsync(data)).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 500:
              doFormMsg(
                {
                  message: intl.formatMessage({ id: 'USERS.RESET.FAILED' }),
                  success: false,
                },
                setFormMsg,
              );
              reject();
              break;
            default:
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'USERS.RESET.SUCCESS',
                  }),
                  success: true,
                },
                setFormMsg,
              );
              setHasReset(true);
              resolve();
              navigateIn('/', 15000);
              break;
          }
        });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'USERS.RESET.FAILED' }),
            success: false,
          },
          setFormMsg,
        );
        console.log('Request failed for unclear reason.', err);
        reject();
      }
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_form narrow"
      title={<FormattedMessage id="USERS.RESET.FORM_TITLE" />}
      onSubmit={handleSubmit}
      noFooter={true}
      refSubmitBtn={refSubmitBtn}
      onCancel={null}
      enableEscape
      formMsg={formMsg}
      extraValidated={validated}
      delayClose={5000}
    >
      <Form.Group as={Row} controlId="Email">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.LOGIN.EMAIL" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="email"
            name="email"
            autoComplete="username email"
            value={data.email || ''}
            onChange={handleChangeData}
            required
            // pattern={EMAIL_PATTERN}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.LOGIN.EMAIL_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="Password">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.RESET.PASSWORD" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="password"
            name="password"
            autoComplete="password"
            value={data.password || ''}
            onChange={handleChangeData}
            required
            pattern={PASSWORD_PATTERN}
          />
          <Form.Text className="text-muted instruction">
            <FormattedMessage id="USERS.SIGNUP.PASSWORD_HINT" />
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.SIGNUP.PASSWORD_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="Password2">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.RESET.PASSWORD2" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="password"
            name="password2"
            onChange={handleChangeData}
            required
          />
          {/* <Form.Text className="text-muted instruction"></Form.Text> */}
          {/* <Form.Control.Feedback type="invalid">
                <div className="feedback">
                  <FormattedMessage id="USERS.SIGNUP.PASSWORD_FDBCK" />
                </div>
              </Form.Control.Feedback> */}
        </Col>
      </Form.Group>
      <br />
      <Container fluid="md">
        <Form.Group as={Row} className="form">
          <FormMessage {...formMsg} key={formMsg.key} />
          <Button
            variant="primary wide"
            ref={refSubmitBtn}
            // disabled={hasReset}
          >
            <FormattedMessage id={hasReset ? 'X.OK' : 'USERS.RESET.SUBMIT'} />
          </Button>
        </Form.Group>
      </Container>
      <Form.Group as={Row} className="form">
        <Col align={'left'} sm={12}>
          <div className="vspaced">
            <FormattedMessage id="USERS.LOGIN.OTHER_OPTIONS" />
            <UserLoginFormLink onClick={() => setShow(false)}>
              <FormattedMessage id="USERS.RESET.REMEMBERED" />
            </UserLoginFormLink>
            {/* <UserSignupFormLink onClick={() => setShow(false)}>
              <FormattedMessage id="USERS.LOGIN.NO_ACCOUNT" />
            </UserSignupFormLink> */}
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

export function UserResetPwdFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, className, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserResetPwdForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={classNames(className)}>
      {children || <FormattedMessage id="NAVIGATION.LOGIN" />}
    </div>
  );
}
