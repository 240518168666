import { SyntheticEvent, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';

type CreateDialogueFormProps = {
  setDialogueTitle: (s: string) => void;
};

export default function CreateDialogueForm(
  props: CreateDialogueFormProps & FormProps,
) {
  const { show, setShow } = props;
  const intl = useIntl();
  const [data, setData] = useState<string>('');

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    setData('');
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  const handleChangeData = (e: SyntheticEvent) => {
    doFormMsg(NoFormMsg, setFormMsg);
    const target = e.target as HTMLInputElement;
    setData(target.value);
  };

  async function handleSubmit(): Promise<void> {
    // TODO: check for validation of the form
    props.setDialogueTitle(data);
    props.onSubmit && props.onSubmit(data);
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      title={<FormattedMessage id="DIALOGUE.FORM.NEW" />}
      onSubmit={handleSubmit}
      submitBtnText={intl.formatMessage({ id: 'DIALOGUE.FORM.CREATE' })}
      editing={true}
      enableEscape
      formMsg={formMsg}
    >
      <Form.Group as={Row} controlId="DialogueTitle">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="text"
            name="title"
            value={data || ''}
            onChange={handleChangeData}
            placeholder={intl.formatMessage({ id: 'DIALOGUE.FORM.TITLE_PLCH' })}
            required
            minLength={3}
          />
        </Col>
      </Form.Group>
      <Container fluid="md">
        <Form.Group as={Row} className="form">
          <FormMessage {...formMsg} key={formMsg.key} />
        </Form.Group>
      </Container>
    </ModalDialog>
  );
}
