import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { current } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Status } from '@thuas/pd-schemas/built/generated/api-types';
import { useAppDispatch } from 'app/hooks';
import Participants from 'components/dialogues/Participants';
import FileUpload from 'components/files/FileUpload';
import FormattedMessage from 'components/formatters/FormattedMessage';
import PeriodPicker from 'components/formatters/PeriodPicker';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog, showModal } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import {
  SortableContainer,
  SortableInfo,
  SortableItem,
  SortableItemType,
  SortableList,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { emptyDialogue } from 'features/dialogue/dialogueSlice';
import { IFile } from 'features/files/fileAPI';
import { imageFileTypes } from 'features/files/fileSlice';
import { IProject, IProjectCreate } from 'features/projects/projectsAPI';
import {
  ProjectsStateProps,
  emptyProject,
  mapProjectsStateToProps,
  patchProjectAsync,
  postPatchAndDeleteDialogues,
  postProjectAsync,
} from 'features/projects/projectsSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { WrapIf } from 'helpers/HelperComponents';
import { copyLink, generateUuidCode } from 'helpers/helpers';
import { AnyIdObject, AnyOrderObject, sameId } from 'helpers/objects';
import { getOrderForLast, getOrderUsingId, sortByOrder } from 'helpers/sorting';
import { useForms } from './Forms';

import './SettingsForm.scss';

type ProjectFormProps = {
  project: IProject;
};

function UnconnectedProjectForm(
  props: ProjectFormProps & FormProps & UserStateProps & ProjectsStateProps,
) {
  const {
    show,
    setShow,
    onSubmit,
    onCancel,
    onClose,
    project,
    userCanEdit,
    projects,
    userIsManager,
  } = props;
  const intl = useIntl();
  const forms = useForms();
  const [editing, setEditing] = useState(false);
  const [reset, setReset] = useState(true);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IProject>({
    ...emptyProject,
  });
  const [dialogues, setDialogues] = useState<IDialogue[]>([]);
  const [deletedDialogueIds, setDeletedDialogueIds] = useState<number[]>([]);
  const [subscribers, setSubscribers] = useState<IUser[]>([]);
  const [moderators, setModerators] = useState<IUser[]>([]);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const [file, setFile] = useState<File | undefined>(undefined); // for avatar image
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (reset || show) {
      let proj: IProject | undefined;
      let isNew = false;
      if (project.id || data?.id)
        proj = projects.find(
          (p: IProject) => p.id === project.id || p.id === data?.id,
        );
      if (!proj) {
        isNew = true;
        proj = { ...emptyProject };
      } else isNew = false;
      setData({ ...proj });
      setDialogues(proj.dialogues as IDialogue[]);
      setDeletedDialogueIds([]);
      setSubscribers(proj.subscribers as IUser[]);
      setModerators(proj.moderators as IUser[]);
      setFile(undefined);
      setPreviewSrc(proj.background?.uri ?? '');
      if (!reset) setEditing(isNew);
      if (reset && isNew) setShow(false);
      setReset(false);
    } else setData({ ...emptyProject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, project, reset, show, setShow]);

  useEffect(() => {
    const ta = document.getElementById('projectDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 3, 8)[0] + 'px';
    }
  }, [show, data]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    const code =
      name === 'status' &&
      target.value === Status.private &&
      !data.registrationCode?.length
        ? generateUuidCode()
        : undefined;
    setData({
      ...data,
      registrationCode: code ?? data.registrationCode,
      [name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!editing) {
          resolve();
          return;
        }
        if (data && !!project.id) {
          // existing project
          let res = await dispatch(
            postPatchAndDeleteDialogues({
              dialogues,
              deletedDialogueIds,
              stateMan,
            }),
          );
          if (
            res.payload &&
            (((res.payload as any).status &&
              (res.payload as any).status >= 300) ||
              ((res.payload as any).response &&
                (res.payload as any).response.status >= 300))
          )
            throw new Error(
              'Dialogue not deleted. Does it still have content?',
            );
          dispatch(
            patchProjectAsync({
              data: {
                ...(data as IProject),
                dialogues,
                subscribers,
                moderators,
              },
              file: file,
              project: project,
            }),
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                doFormMsg(
                  {
                    message: 'Project updated',
                    success: true,
                    timeout: 1500,
                  },
                  setFormMsg,
                );
                setEditing(false); // we can stop editing
                onSubmit && onSubmit();
                resolve();
                break;
              case 500:
              default:
                const m = 'Internal error, project settings not saved.';
                doFormMsg(
                  {
                    message: m,
                    success: false,
                  },
                  setFormMsg,
                );
                reject(m);
                break;
            }
          });
        } else {
          // new project
          dispatch(postProjectAsync({ data: data as IProjectCreate })).then(
            (response: { payload: any }) => {
              switch (response.payload?.response.status) {
                case 200:
                case 201:
                  setData({ ...data, id: response.payload?.response.data._id });
                  doFormMsg(
                    {
                      message: 'Project created.',
                      success: true,
                      timeout: 1500,
                    },
                    setFormMsg,
                  );
                  setEditing(false); // we can stop editing
                  onSubmit && onSubmit();
                  resolve();
                  break;
                case 500:
                default:
                  doFormMsg(
                    {
                      message: 'Internal error, project not created.',
                      success: false,
                    },
                    setFormMsg,
                  );
                  reject();
                  break;
              }
            },
          );
        }
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg,
        );
        console.log(
          'Project update or post request failed for unclear reason.',
          err,
        );
        reject();
      }
    });
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    onCancel && onCancel();
    setEditing(false);
    setReset(true);
    return true;
  }

  function handleClose(): void {
    !editing && onClose && onClose();
  }

  function renderDialogueItem(dialogue: IDialogue) {
    function removeDialogue(id: number) {
      function doDelete(): boolean {
        // add the dialogue id to the deletedDialogueIds array
        setDeletedDialogueIds((prev) => [...prev, id]);
        // remove the dialogue from the dialogues array
        setDialogues(dialogues.filter((dlg) => dlg.id !== id));
        return true;
      }
      showModal(
        <FormattedMessage id="PROJECT.FORM.DIALOGUES.DELETE.DIALOGUE" />,
        <>
          <div className="title">
            <FormattedMessage id="PROJECT.FORM.DIALOGUES.DELETE.DIALOGUE_CNF" />
          </div>
          <div className="text">
            <FormattedMessage id="PROJECT.FORM.DIALOGUES.DELETE.DIALOGUE_CNF2" />
          </div>
          <Icon
            symbol={IconSymbol.alert}
            variant={IconVariant.accent}
            size={80}
          />
          <div className="text">
            <FormattedMessage id="GENERAL.DELETE.NOTICE" />
          </div>
        </>,
        {
          intl: intl,
          className: 'pd_alert',
          submitBtnText: <FormattedMessage id="X.DELETE" />,
          onSubmit: doDelete,
          editing: true,
          cancelDefault: true,
        },
      );
    }

    function handleChangeDialogueData(e: SyntheticEvent) {
      const target = e.target as HTMLInputElement;
      setDialogues(
        dialogues.map((d) => {
          if (d.id === dialogue.id) {
            if (target.type === 'checkbox')
              return { ...d, [target.name]: target.checked };
            return { ...d, [target.name]: target.value };
          }
          if (target.type === 'checkbox')
            return { ...d, [target.name]: !target.checked };
          return d;
        }),
      );
    }
    function setDialogueDates(from?: Date | null, to?: Date | null) {
      setDialogues(
        dialogues.map((d) => {
          if (d.id === dialogue.id) {
            const newDlg = {
              ...d,
              startedAt: from !== undefined ? from : d.startedAt,
              closedAt: to !== undefined ? to : d.closedAt,
            };
            return newDlg;
          }
          return d;
        }),
      );
    }
    function copyDatesFromProject() {
      setDialogues(
        dialogues.map((d) => {
          if (d.id === dialogue.id) {
            const newDlg = {
              ...d,
              startedAt: data.startedAt,
              closedAt: data.closedAt,
            };
            return newDlg;
          }
          return d;
        }),
      );
    }

    return (
      <div
        key={dialogue.id}
        className="settings_item dialogue_item as-form-control sortable_content"
      >
        <div className="settings_col img">
          <WrapIf
            condition={!editing}
            wrapper={
              <Link
                to={`/dialogue/${dialogue.id}`}
                onClick={() => {
                  setShow(false);
                  handleClose();
                }}
              />
            }
          >
            <img
              src={
                dialogue.background
                  ? (dialogue.background as IFile).uri
                  : '/img/backgroundImage.png'
              }
              alt="Dialogue illustration"
            />
          </WrapIf>
        </div>
        <div className="settings_col descr" data-no-dnd={true}>
          <Form.Group as={Row} controlId={'dialogueTitle'}>
            <Col sm={12} md={12} lg={12}>
              <Form.Control
                className="field subtitle"
                type="text"
                disabled={!editing}
                placeholder={intl.formatMessage({
                  id: 'DIALOGUE.FORM.TITLE_PLCH',
                })}
                name="name"
                value={dialogue.title || ''}
                onChange={handleChangeDialogueData}
                required
              />
            </Col>
          </Form.Group>
          {dialogue.subscribers || dialogue.moderators ? (
            <Participants
              project={dialogue.project}
              subscribers={dialogue.subscribers as IUser[]}
              moderators={dialogue.moderators as IUser[]}
            />
          ) : null}
        </div>
        <div className="settings_col period">
          <PeriodPicker
            from={dialogue.startedAt as Date}
            setFrom={(date: Date | null) => {
              setDialogueDates(date);
            }}
            to={dialogue.closedAt as Date}
            setTo={(date: Date | null) => {
              setDialogueDates(undefined, date);
            }}
            showDate={true}
            showYear={true}
            editing={editing}
          />
          {editing ? (
            <div className="copy_dates" onClick={copyDatesFromProject}>
              <FormattedMessage id="PROJECT.FORM.DIALOGUES.COPY_DATES" />
            </div>
          ) : null}
          {editing && userCanEdit(project) ? (
            <Icon
              symbol={IconSymbol.bin}
              className="dialogue_remove"
              onClick={() => removeDialogue(dialogue.id as number)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  function newDialogueId(): number {
    if (!dialogues.length) return -1;
    return (
      (dialogues as AnyIdObject[]).reduce((lowestId, dlg) => {
        return Math.min(dlg.id, lowestId);
      }, 0) - 1
    );
  }
  function handleSubmitNewDlg(title: string): boolean {
    const newDlg: IDialogue = {
      ...emptyDialogue,
      id: newDialogueId(),
      title: title,
      project: data as IProject,
      background: null,
      keywords: [],
      subscribers: [],
      moderators: [],
      userFeedbacks: [],
      phases: [],
      startedAt: data.startedAt,
      closedAt: data.closedAt,
    };
    const newDialogues = [...dialogues];
    newDlg.order = getOrderForLast(newDialogues as AnyOrderObject[]);
    newDialogues.push(newDlg);
    setDialogues(newDialogues);
    return true;
  }

  function addDialogue() {
    try {
      forms.CreateDialogueForm.setDialogueTitle('');
      forms.CreateDialogueForm.setOnSubmit(
        // need to pass this as a function, because a SetStateAction will be evaluated (executed)
        () => {
          return handleSubmitNewDlg;
        },
      );
      forms.CreateDialogueForm.setShow(true);
    } catch {
      console.log(
        'Dialogue could not be created for unclear defaultScreenReaderInstructions.',
      );
    }
  }

  function ProjectStatus() {
    return (
      <Form.Group as={Row} controlId="projectStatus">
        <Col sm={12} md={12} lg={12}>
          <Form.Select
            className="field status"
            disabled={disabled}
            name="status"
            value={data.status || ''}
            onChange={handleChangeData}
            required
          >
            <option value={Status.draft}>
              <FormattedMessage id="PROJECT.STATUS.DRAFT" />{' '}
              <FormattedMessage id="PROJECTS.PROJECT" lowercase />
            </option>
            <option value={Status.private}>
              <FormattedMessage id="PROJECT.STATUS.PRIVATE" />{' '}
              <FormattedMessage id="PROJECTS.PROJECT" lowercase />
            </option>
            <option value={Status.public}>
              <FormattedMessage id="PROJECT.STATUS.PUBLIC" />{' '}
              <FormattedMessage id="PROJECTS.PROJECT" lowercase />
            </option>
            <option value={Status.closed}>
              <FormattedMessage id="PROJECT.STATUS.CLOSED" />{' '}
              <FormattedMessage id="PROJECTS.PROJECT" lowercase />
            </option>
          </Form.Select>
          <Form.Text className="text-muted instruction" hidden={!editing}>
            <FormattedMessage id="PROJECT.FORM.STATUS_INSTR" />
          </Form.Text>
        </Col>
      </Form.Group>
    );
  }

  function reorderDialogues(
    sortableInfo: SortableInfo,
    item: AnyOrderObject,
    sourceList: SortableListInfo,
    destination?: AnyOrderObject,
    destinationList?: SortableListInfo,
  ) {
    if (!destination) return;
    if (sameId(item, destination)) return;
    const before = destination
      ? extractClosestEdge(
          sortableInfo.location.current.dropTargets[0].data,
        ) === 'top'
      : false;
    if (!dialogues) return;
    const sortedDialogues = sortByOrder(dialogues as AnyOrderObject[]);
    const newOrder = getOrderUsingId(sortedDialogues, item.id, destination.id);
    setDialogues((prev) =>
      prev.map((d) => {
        if (sameId(d.id, item.id)) return { ...d, order: newOrder };
        return d;
      }),
    );
  }

  function setProjectDates(from?: Date | null, to?: Date | null) {
    setData({
      ...data,
      startedAt: from !== undefined ? from : data.startedAt,
      closedAt: to !== undefined ? to : data.closedAt,
    });
  }

  function stateMan(obj: any): any {
    console.log('current(obj): ', current(obj));
    return obj?.projects;
  }

  if (!data) return null;
  const listinfo: SortableListInfo = editing
    ? {
        id: 1,
        listType: SortableListType.Dialogues,
        items: sortByOrder(dialogues as AnyOrderObject[]),
        canvas: false,
      }
    : { id: 0, listType: SortableListType.Dialogues, items: [], canvas: false };
  const disabled = !(editing || !project);
  const gap = 5;
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form wide project_form"
      title={null} // no title for this dialog
      onSubmit={handleSubmit}
      onCancel={userCanEdit(project) ? handleCancel : undefined}
      onClose={handleClose}
      allowEdit={userCanEdit(project) && !!project}
      onEdit={() => setEditing(true)}
      editing={!disabled}
      formMsg={formMsg}
      footer={
        editing &&
        data.id && (
          <div className="admin_section">
            <Button onClick={addDialogue} variant="outline-secondary">
              <FormattedMessage id="DIALOGUES.CREATE" />
            </Button>
          </div>
        )
      }
    >
      <div className="header">
        <FormattedMessage
          id={
            project.status
              ? `PROJECT.STATUS.${project.status.toUpperCase()}`
              : ''
          }
        />{' '}
        <FormattedMessage id="PROJECTS.PROJECT" />
      </div>
      <Form.Group as={'div'} className="settings_item">
        <div className="settings_col descr">
          <Form.Control
            className="field title"
            type="text"
            disabled={disabled}
            placeholder={intl.formatMessage({
              id: 'PROJECT.FORM.TITLE_PLCH',
            })}
            name="title"
            value={data.title || ''}
            onChange={handleChangeData}
            required
            minLength={3}
          />
          {editing || data.subtitle ? (
            <Form.Control
              className="field subtitle"
              type="text"
              disabled={disabled}
              placeholder={intl.formatMessage({
                id: 'PROJECT.FORM.SUBTITLE_PLCH',
              })}
              name="subtitle"
              value={data.subtitle || ''}
              onChange={handleChangeData}
            />
          ) : null}
          {editing && userIsManager ? <ProjectStatus /> : null}
          <Form.Control
            className="field text"
            type="text"
            disabled={disabled}
            placeholder={intl.formatMessage({
              id: 'PROJECT.FORM.DESCRIPTION_PLCH',
            })}
            as="textarea"
            rows={3}
            name="description"
            value={data.description || ''}
            onChange={handleChangeData}
            required
          />
        </div>
        <div className="settings_col img img_r">
          <FileUpload
            types={imageFileTypes}
            currentSrc={
              data.background ? (data.background as IFile).uri! : null
            }
            previewSrc={previewSrc}
            setPreviewSrc={setPreviewSrc}
            defaultImg={<img src="/img/backgroundImage.png" alt="" />}
            showMsg={(m) => doFormMsg(m, setFormMsg)}
            setFile={setFile}
            onDelete={() => {
              // set the id to null to indicate the image needs to be deleted
              data.background = null;
            }}
            confirmationText={
              <FormattedMessage id="PROJECT.FORM.DELETE_BACKGROUND_CNF" />
            }
            disabled={!editing}
            withTrigger={true}
            showTrigger={true}
          />
        </div>
      </Form.Group>
      <Form.Group as={Row} controlId="regCode" className="reg_access">
        {userCanEdit(project) ? (
          <Col sm={12} md={12} lg={12} className="project_regcode">
            <div className="regcode label">
              <FormattedMessage id="PROJECT.FORM.REGCODE" />
              :&nbsp;
              {userIsManager ? (
                <>
                  {editing ? (
                    <>
                      <Icon
                        className="reg_access_link"
                        symbol={IconSymbol.cycle}
                        hoverVariant={IconVariant.dark}
                        onClick={() => {
                          setData({
                            ...data,
                            registrationCode: generateUuidCode(),
                          });
                        }}
                        hintProps={{
                          hint: intl.formatMessage({
                            id: 'PROJECT.FORM.REGCODE_GENERATE',
                          }),
                          offset: { x: 20, y: 20 },
                          offsetRight: false,
                        }}
                        size={16}
                        inline
                      />
                      <Form.Control
                        className="field text"
                        type="text"
                        disabled={disabled}
                        placeholder={
                          editing
                            ? intl.formatMessage({
                                id: 'PROJECT.FORM.REGCODE_PLCH',
                              })
                            : '-'
                        }
                        name="registrationCode"
                        value={data.registrationCode || ''}
                        onChange={handleChangeData}
                        // required
                      />
                    </>
                  ) : (
                    data.registrationCode
                  )}
                </>
              ) : (
                data.registrationCode
              )}
              <Icon
                className="reg_access_link"
                symbol={IconSymbol.copy}
                hoverVariant={IconVariant.dark}
                onClick={() => {
                  copyLink(`signup/${data.registrationCode}`, setCopied);
                }}
                hintProps={{
                  hint: intl.formatMessage({
                    id: copied
                      ? 'PROJECT.FORM.COPIEDLINK'
                      : 'PROJECT.FORM.COPYLINK',
                  }),
                  offset: { x: 20, y: 20 },
                  offsetRight: false,
                }}
                size={16}
                inline
              />
            </div>
          </Col>
        ) : null}
      </Form.Group>
      <Form.Group as={Row} controlId="projectPhasesHeader">
        <Col sm={12} md={12} lg={12}>
          <div className="header">
            <div className="subheading">
              {data.subscribers || data.moderators ? (
                <FormattedMessage id="DIALOGUE.VIEW.PARTICIPATION" />
              ) : null}
            </div>
            <div className="period">
              <FormattedMessage id="PROJECT.VIEW.PERIOD" />
            </div>
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="project_period">
        <Col sm={6} md={6} lg={6}>
          {data.subscribers || data.moderators ? (
            <Participants
              subscribers={subscribers as IUser[]}
              setSubscribers={setSubscribers}
              moderators={moderators as IUser[]}
              setModerators={setModerators}
              // moderators={undefined}
              // setModerators={undefined}
              editing={editing}
            />
          ) : null}
        </Col>
        <Col sm={6} md={6} lg={6}>
          <div className="settings_col period">
            <PeriodPicker
              from={data.startedAt as Date}
              setFrom={(date: Date | null) => {
                setProjectDates(date);
              }}
              to={data.closedAt as Date}
              setTo={(date: Date | null) => {
                setProjectDates(undefined, date);
              }}
              showDate={true}
              showYear={true}
              editing={editing}
            />
            {/* {editing && userIsManager ? (
                <Icon
                  symbol={IconSymbol.bin}
                  className="project_remove"
                  // onClick={() => removeProject(data.id as number)}
                />
              ) : null} */}
          </div>
        </Col>
      </Form.Group>
      {data.id ? (
        <>
          <Form.Group as={Row} controlId="projectDialoguesHeader">
            <Col sm={12} md={12} lg={12}>
              <div className="header">
                <div className="title">
                  <FormattedMessage id="PROJECT.VIEW.DIALOGUES" />
                </div>
                {/* <div className="period">
                  <FormattedMessage id="PROJECT.VIEW.PERIOD" />
                </div> */}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="projectDialogues">
            <Col sm={12} md={12} lg={12}>
              <div className="dialogues">
                {data && dialogues.length ? (
                  editing ? (
                    <>
                      <SortableContainer reorder={reorderDialogues}>
                        <SortableList
                          listInfo={listinfo}
                          accepting={[SortableItemType.Dialogue]}
                          gap={gap}
                        >
                          {sortByOrder(dialogues as AnyOrderObject[]).map(
                            (dlg, i) => {
                              return (
                                <SortableItem
                                  key={dlg.id}
                                  listInfo={listinfo}
                                  item={dlg}
                                  itemType={SortableItemType.Dialogue}
                                  index={i}
                                  withHandle={false}
                                  gap={gap}
                                >
                                  {renderDialogueItem(dlg)}
                                </SortableItem>
                              );
                            },
                          )}
                        </SortableList>
                      </SortableContainer>
                    </>
                  ) : (
                    sortByOrder(dialogues as AnyOrderObject[]).map(
                      (dlg: IDialogue, i) => {
                        return renderDialogueItem(dlg);
                      },
                    )
                  )
                ) : (
                  <div className="form-control-padding">
                    <FormattedMessage id="PROJECT.FORM.NO_DIALOGUES" />
                  </div>
                )}
              </div>
            </Col>
          </Form.Group>
        </>
      ) : null}
    </ModalDialog>
  );
}

const ProjectForm = connect(mapProjectsStateToProps)(
  connect(mapUserStateToProps)(UnconnectedProjectForm),
);
export default ProjectForm;

export function ProjectFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  project?: IProject;
  tag?: 'span' | 'div';
  title?: string;
  children?: ReactNode;
}) {
  const { onClick, project, children, title, tag, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.ProjectForm.setProject(project || (emptyProject as IProject));
    forms.ProjectForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
