import classNames from 'classnames';
import { EmojiClickData } from 'emoji-picker-react';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import EmojisButton from 'components/icons/EmojisButton';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';

type TextAreaInputProps = {
  value: string | null;
  original?: string | null;
  placeholder?: string;
  asInput?: boolean;
  minrows?: number;
  maxrows?: number;
  onChange?: (input: string, hasText: boolean) => void;
  onBlur?: (e?: React.FocusEvent) => void;
  onSubmit: (
    input: string,
    hasText: boolean,
    event?: React.KeyboardEvent | React.MouseEvent,
  ) => void;
  escapeClears?: boolean;
  emojiesBtn?: boolean;
  className?: string;
};
export default function TextAreaInput(props: TextAreaInputProps) {
  const {
    value = '',
    original = null,
    placeholder = '',
    asInput = false,
    minrows = 1,
    maxrows = 3,
    onChange,
    onBlur,
    onSubmit,
    escapeClears = true,
    emojiesBtn = false,
    className,
  } = props;
  const taRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>('');
  const cursorPos = useRef<number | undefined>();

  useEffect(() => {
    if (asInput) return;
    const ta = taRef.current as HTMLTextAreaElement;
    if (ta) {
      const timer = setTimeout(() => {
        const taH = getTextAreaHeight(
          ta as HTMLTextAreaElement,
          minrows,
          maxrows,
          true,
          4,
        );
        if (taH[0]) ta.style.height = taH[0] + 'px';
      }, 10);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [asInput, maxrows, minrows, text, value]);

  useEffect(() => {
    setText(value || '');
    taRef.current?.focus();
  }, [value]);

  function handleMsgChange(e: SyntheticEvent) {
    const val = (e.target as HTMLTextAreaElement).value;
    setText(val);
    onChange && onChange(val, !justWhitespace(val));
  }

  function handleSubmit(e?: React.KeyboardEvent | React.MouseEvent) {
    onSubmit(text, !justWhitespace(text), e);
  }

  function handleCancel() {
    setText(value || '');
    onChange && onChange(value || '', false);
    onBlur && onBlur();
  }

  function handleInputKeyDown(e: React.KeyboardEvent) {
    if (escapeClears && e.key === 'Escape') {
      handleCancel();
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  function handleFocus(event: SyntheticEvent) {
    const ta = event.currentTarget as HTMLTextAreaElement;
    if (ta) {
      ta.selectionStart = cursorPos.current
        ? cursorPos.current
        : ta.value.length;
      ta.selectionEnd = cursorPos.current ? cursorPos.current : ta.value.length;
    }
  }

  function handleBlur(e?: React.FocusEvent) {
    const d = e?.relatedTarget;
    if (d?.classList.contains('emoji_picker_button')) return;
    if (original !== null && original !== text) {
      e?.preventDefault();
      taRef.current?.focus();
    } else if (onBlur) onBlur(e);
  }

  function handleEmojiSelect(emojiData: EmojiClickData, event: MouseEvent) {
    const ta = taRef.current as HTMLTextAreaElement;
    if (ta) {
      const val = ta.value;
      const newVal =
        val.substring(0, ta.selectionStart) +
        (emojiData.isCustom ? emojiData.unified : emojiData.emoji) +
        // emojiData.unified +
        val.substring(ta.selectionStart);
      setText(newVal);
      onChange && onChange(newVal, !justWhitespace(newVal));
      cursorPos.current = ta.selectionStart + 2;
    }
  }

  function handleEmojisClose() {
    taRef?.current?.focus();
  }

  return (
    <>
      <Form.Control
        autoComplete="off"
        onBlur={handleBlur}
        onChange={handleMsgChange}
        onKeyDown={handleInputKeyDown}
        onFocus={handleFocus}
        type="text"
        as={asInput ? undefined : 'textarea'}
        rows={1}
        className={classNames('msg_input_field', className)}
        ref={taRef}
        placeholder={placeholder}
        value={text}
      />
      {emojiesBtn ? (
        <EmojisButton
          onSelect={handleEmojiSelect}
          ref={taRef}
          onClose={handleEmojisClose}
        />
      ) : null}
      {original !== null ? (
        <>
          <Icon
            symbol={IconSymbol.check}
            variant={IconVariant.accent}
            className="msg_input_btn"
            size={18}
            onClick={handleSubmit}
          />
          <Icon
            symbol={IconSymbol.check_not}
            className="msg_input_btn"
            size={18}
            onClick={handleCancel}
          />
        </>
      ) : null}
    </>
  );
}

// gets the height of a textarea that is necessary to show all its contents
export function getTextAreaHeight(
  ta: HTMLTextAreaElement,
  minlines: number = 0,
  maxlines: number = 0,
  useMinLines: boolean = false,
  addPx: number = 0,
  // debug: boolean = false
): number[] {
  try {
    if (!ta) return [0, 0, 0];
    const root = document.getElementById('root');
    if (!root) {
      console.log('cannot find root');
      return [0, 0, 0];
    }
    let text = ta.value.replace(/\n/g, '<br>');
    // we need at least some text to get minimum 1 line height
    if (justWhitespace(text)) text = 'x';
    // note: adding hd to ta will not work because closed blocks will lead to zero height
    // let hd = findFirstSiblingWithClass(ta, 'tadiv');
    // ta.parentElement!.appendChild(hd);
    const hd = document.createElement('div');
    hd.className = 'tadiv';
    root.appendChild(hd);
    hd.innerHTML = text;
    const style = getComputedStyle(ta);
    hd.style.position = 'absolute';
    hd.style.top = '-9999px';
    hd.style.left = '0px';
    // need a minimum width to get a useful height value
    hd.style.width = Math.max(ta.clientWidth, 20) + 'px';
    hd.style.height = 'auto';
    hd.style.font = style.font;
    hd.style.fontSize = style.fontSize;
    hd.style.color = 'transparent';
    hd.style.padding = style.padding;
    let height = hd.clientHeight;
    const lineHeight = parseFloat(style.lineHeight);
    let lines = Math.floor(height / lineHeight);
    if (maxlines && maxlines < lines) {
      height = maxlines * lineHeight;
      lines = maxlines;
    } else if (useMinLines && minlines && minlines > lines) {
      height = minlines * lineHeight;
      lines = minlines;
    }

    if (root && hd && root.contains(hd)) {
      try {
        root.removeChild(hd);
      } catch (error) {
        console.error('An error occurred while removing the child:', error);
      }
    } else {
      console.warn('Child element not found within parent.');
    }

    return [height + addPx, lines, lineHeight];
  } catch (error) {
    console.log(error);
    return [0, 0, 0];
  }
}

export function justWhitespace(text: string): boolean {
  return /^[ \t\n]*$/.test(text);
}
