import { Rect, Svg, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import classNames from 'classnames';
import {
  BorderStyle,
  ISectionOptions,
  ImageRun,
  Paragraph,
  SectionType,
  ShadingType,
  Table,
  TableBorders,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
} from 'docx';
import {
  Dispatch,
  Fragment,
  MouseEventHandler,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import Countdown, { calcTimeDelta } from 'react-countdown';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { getUnreadMessagesCount, registerUnreadCounter } from 'app/unreadCount';
import Collapsable from 'components/collapsable/Collapsable';
import FileUpload from 'components/files/FileUpload';
import FormattedDate from 'components/formatters/FormattedDate';
import Icon, { IconSymbol } from 'components/icons/Icon';
import Message, {
  MessageToPdf,
  messageToDocx,
} from 'components/messages/Message';
import TextAreaInput, {
  justWhitespace,
} from 'components/messages/TextAreaInput';
import DropMenu from 'components/navigation/DropMenu';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import {
  SortableItem,
  SortableItemType,
  SortableList,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/Sortable';
import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { imageFileTypes } from 'features/files/fileSlice';
import { IOption, IPoll, IVote } from 'features/poll/pollAPI';
import {
  deleteVoteAsync,
  postOptionAsync,
  postVoteAsync,
} from 'features/poll/pollSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { calculateRoundedPercentages } from 'helpers/calculus';
import { IS_DEV } from 'helpers/consts';
import { ExportProps } from 'helpers/export';
import { getGaugeStyle } from 'helpers/helpers';
import { createBase64Pixel } from 'helpers/images';
import { AnyOrderObject, sameId } from 'helpers/objects';
import { getOrderUsingIndex, sortByOrder } from 'helpers/sorting';
import { ChildBlockProps } from './Block';

import { useDialogueContext } from 'components/dialogues/DialogueView';
import { SortableContainer } from 'components/sortables/Sortable';
import styles from 'css/pdfStyles';
import './PollBlock.scss';

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const count =
    getUnreadMessagesCount(
      block.childPollBlock?.poll?.options?.map((o) => o.message ?? {}) ?? [],
      lastVisited,
    ) +
    (!!block.childPollBlock?.poll?.options
      ? getUnreadMessagesCount(
          block.childPollBlock?.poll?.options.reduce(
            (accumulator: IVote[], o: IOption) => {
              return accumulator.concat(o.votes ?? []);
            },
            [],
          ),
          lastVisited,
          'voted',
        )
      : 0);
  return count;
}
registerUnreadCounter(BlockType.Poll, getUnread);

type PollState =
  | 'none' // no poll defined
  | 'editing' // no start time defined
  | 'notstarted' //  start time in future
  | 'started' // start time in the past, end time in the future
  | 'eternal' // start time in the past, no end time defined
  | 'ended' // start time and end time in the past
  | 'closed'; // closed attribute = true, regardless of end time

function pollIsOpen(pollState: PollState): boolean {
  return ['started', 'eternal'].includes(pollState);
}
function pollHasEnded(pollState: PollState): boolean {
  return ['ended', 'closed'].includes(pollState);
}
function pollIsEditable(poll: IPoll | null, pollState: PollState): boolean {
  if (!poll) return true;
  let editable = ['none', 'editing', 'notstarted'].includes(pollState);
  if (!editable)
    editable = !(poll.options?.some((o) => o.votes?.length) ?? false);
  return editable;
}

export default function PollBlock(props: ChildBlockProps) {
  const { block, onUpdate } = props;
  const [poll, setPoll] = useState<IPoll | null>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [hasNewMsg, setHasNewMsg] = useState<boolean>(false);
  const [pollState, setPollState] = useState<PollState>('none');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setPoll(block.childPollBlock?.poll ?? null);
  }, [block]);

  useEffect(() => {
    setPollState(
      poll
        ? poll.closed
          ? 'closed' // poll manually closed
          : poll.start // check for start date
            ? new Date(poll.start).getTime() > Date.now()
              ? 'notstarted' // we have a start date in the future
              : poll.end // start date has passed, check end date
                ? new Date(poll.end).getTime() > Date.now()
                  ? 'started' // we have an end date in the future
                  : 'ended' // end date has passed
                : 'eternal' // started with no end date
            : 'editing' // no start date was set
        : 'none', // no poll
    );
  }, [poll]);

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childPollBlock;
  }

  const [webSocket] = useState(AsyncAPI.connection);

  // TODO: partly updated to poll block, but not at the end.
  useEffect(() => {
    if (block.childPollBlock == null) return;

    const asyncAPIQuery = `PollBlock/${
      block.childPollBlock?.id
    }?and=${constructURL({
      poll: {
        '*': true,
        options: {
          '*': true,
          message: {
            '*': true,
            author: {
              // '*': true,
              about: true,
              email: true,
              id: true,
              isVerified: true,
              locale: true,
              username: true,
            },
            files: {
              '*': true,
            },
          },
          votes: {
            '*': true,
            voter: {
              id: true,
            },
          },
        },
      },
    } as const satisfies Retrieving<'PollBlock'>)}`;

    console.log(
      `Main useEffect called! (PollBlock) - id: ${block.childPollBlock.id}`,
    );

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (PollBlock) --> connection is not ready, readyState is not on 1',
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      // switch (change) {
      //   case 'new':
      //     dispatch(asyncApiPollsNew({ data: payload, stateMan }));
      //     break;
      //   case 'updated':
      //     dispatch(asyncApiPollsUpdated({ data: payload, stateMan }));
      //     break;
      //   case 'removed':
      //     dispatch(asyncApiPollsRemoved({ data: payload, stateMan }));
      //     break;
      // }
      onUpdate();
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (Messages) --> connection is not ready, readyState is not on 1',
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback,
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket?.readyState, block.childPollBlock?.id]);

  return (
    <>
      {/* {pollState} */}
      <ScrollObserver
        scrollableRef={scrollableRef}
        toBottom={false}
        mutations={true}
        customScrollbar={false}
        shouldScrollToBottom={hasNewMsg}
      >
        <div
          className={classNames('block_content', { hasNewMsg: hasNewMsg })}
          ref={scrollableRef}
        >
          <PollBlockContent
            block={block}
            poll={poll}
            setPoll={setPoll}
            pollState={pollState}
            setPollState={setPollState}
            showDescription={props.showDescription}
            scrollableRef={scrollableRef}
            stateMan={stateMan}
          />
        </div>
      </ScrollObserver>
      {!block.locked ? (
        <div className="block_tail">
          <PollBlockTail
            poll={poll}
            pollState={pollState}
            stateMan={stateMan}
            setHasNewMsg={setHasNewMsg}
          />
        </div>
      ) : null}
    </>
  );
}

type PollBlockContentProps = {
  block: IBlock;
  poll: IPoll | null;
  setPoll: Dispatch<SetStateAction<IPoll | null>>;
  pollState: PollState;
  setPollState: Dispatch<SetStateAction<PollState>>;
  showDescription?: boolean;
  scrollableRef: React.RefObject<HTMLDivElement>;
  stateMan: StateMan;
};

function UnconnectedPollBlockContent(
  props: PollBlockContentProps & UserStateProps & DialogueStateProps,
) {
  const {
    block,
    poll,
    pollState,
    setPollState,
    scrollableRef,
    user,
    userCanEdit,
    dialogue,
    stateMan,
  } = props;
  const options = sortByOrder(
    (block.childPollBlock?.poll?.options ?? []) as AnyOrderObject[],
  );
  const [selectedOption, setSelectedOption] = useState<number | undefined>(
    undefined,
  );
  const [hasVoted, setHasVoted] = useState<boolean>(false);
  const [voteSectionOpen, setVoteSectionOpen] = useState<boolean>(true);
  const { reorderOptions } = useDialogueContext();

  useEffect(() => {
    const voted = !!poll?.options?.find((o) => {
      return !!o.votes?.find((v) => {
        if (sameId(user, v.voter)) {
          setSelectedOption(o.id);
          return true;
        }
        return false;
      });
    });
    setHasVoted(voted);
  }, [poll, user]);

  function renderTimeRemaining(
    {
      days,
      hours,
      minutes,
      seconds,
    }: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    },
    start = false,
  ) {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0)
      return <FormattedMessage id="POLLS.EXPIRED" />;
    return (
      <>
        {start ? (
          <FormattedMessage id="POLLS.STARTING" />
        ) : (
          <FormattedMessage id="POLLS.ENDING" />
        )}{' '}
        {days < 2 ? (
          <>
            {days > 0 || hours > 0 ? (
              <FormattedMessage
                id="X.TIME.HOURS"
                values={{ count: (days === 1 ? 24 : 0) + hours }}
              />
            ) : null}
            {(days > 0 || hours > 0) && minutes > 0 ? (
              <>
                {' '}
                <FormattedMessage id="X.AND" />{' '}
              </>
            ) : null}
            {minutes > 0 ? (
              <FormattedMessage
                id="X.TIME.MINUTES"
                values={{ count: minutes }}
              />
            ) : hours === 1 && minutes === 0 ? (
              <FormattedMessage id="X.TIME.MINUTES" values={{ count: 60 }} />
            ) : null}
            {days < 1 && hours < 1 && minutes < 10 ? (
              <>
                {' '}
                {minutes > 0 ? (
                  <>
                    <FormattedMessage id="X.AND" />{' '}
                  </>
                ) : null}
                <FormattedMessage
                  id="X.TIME.SECONDS"
                  values={{ count: seconds }}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <FormattedMessage id="X.TIME.ABOUT" />{' '}
            <FormattedMessage
              id="X.TIME.DAYS"
              values={{
                count:
                  days +
                  (days < 4 ? (hours > 15 ? 1 : hours > 8 ? 0.5 : 0) : 0),
              }}
            />
          </>
        )}
      </>
    );
  }

  function PollTiming(): JSX.Element {
    if (!poll) return <></>;
    switch (pollState) {
      case 'editing':
        return (
          <div className="poll_timing poll_timetoend">
            <FormattedMessage id="POLLS.EDITING" />
          </div>
        );
      case 'notstarted':
        return poll.start ? (
          <div className="poll_timing poll_timetostart">
            <div className="poll_countdown">
              <Countdown
                date={new Date(poll.start)}
                renderer={(cdprops) => {
                  return renderTimeRemaining(cdprops, true);
                }}
                onComplete={(td, c) => {
                  setPollState(poll.end ? 'started' : 'eternal');
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case 'started':
        return poll.end ? (
          <div className="poll_timing poll_timetoend">
            {calcTimeDelta(poll.end).days < 2 ? (
              <div className="poll_countdown">
                <Countdown
                  date={new Date(poll.end)}
                  renderer={(cdprops) => {
                    return renderTimeRemaining(cdprops);
                  }}
                  onComplete={(td, c) => {
                    setPollState('ended');
                  }}
                >
                  <FormattedMessage id="POLLS.EXPIRED" />
                </Countdown>
              </div>
            ) : (
              <>
                <FormattedMessage id="POLLS.ENDS" />{' '}
                <FormattedDate
                  value={new Date(poll.end)}
                  format={{
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  }}
                  showYear={true}
                />
                {', '}
                <FormattedTime
                  value={new Date(poll.end)}
                  hour={'2-digit'}
                  minute={'2-digit'}
                />
              </>
            )}
          </div>
        ) : (
          <></>
        );
      case 'eternal':
        return (
          <div className="poll_timing poll_timetoend">
            <FormattedMessage id="POLLS.NOEXPIRE" />
          </div>
        );
      case 'ended':
        return (
          <div className="poll_timing poll_timetoend">
            <FormattedMessage id="POLLS.EXPIRED" />
          </div>
        );
      case 'closed':
        return (
          <div className="poll_timing poll_closed">
            <FormattedMessage id="POLLS.CLOSED" />
          </div>
        );
      case 'none':
      default:
        return (
          <div className="poll_timing poll_closed">
            <FormattedMessage id="POLLS.CLOSED" />
          </div>
        );
    }
  }

  type VoteProps = {
    option: IOption;
    checked: boolean;
    checkOption: (option: IOption | undefined) => void;
  };
  function Vote(props: VoteProps) {
    const { option, checked, checkOption } = props;

    function handleVoteCheck(event: SyntheticEvent) {
      // this allows unchecking the radio button
      checkOption(checked ? undefined : option);
    }

    return (
      <>
        <Form.Check
          className="poll_vote_checkbox"
          checked={checked}
          onClick={handleVoteCheck}
          onChange={handleVoteCheck}
          type={'radio'}
          disabled={
            !(
              pollIsOpen(pollState) ||
              (userCanEdit(dialogue) && !pollHasEnded(pollState))
            ) || hasVoted
          }
        />
      </>
    );
  }

  function VoteButtons() {
    const dispatch = useAppDispatch();

    function handleVote() {
      if (!selectedOption || !(pollIsOpen(pollState) || userCanEdit(dialogue)))
        return;
      dispatch(
        postVoteAsync({
          data: {
            voted: new Date(),
            option: selectedOption,
            voter: user.id ?? 0,
          },
          user: user,
          stateMan: stateMan,
        }),
      ).then(() => {
        setVoteSectionOpen(false);
      });
    }

    function handleCancelVote() {
      if (!hasVoted || !(pollIsOpen(pollState) || userCanEdit(dialogue)))
        return;
      const voteIds: number[] = [];
      poll?.options?.forEach((o: IOption) => {
        o.votes?.forEach((v) => {
          if (sameId(v.voter, user)) voteIds.push(v.id ?? 0);
        });
      });
      voteIds.forEach((vid) => {
        if (vid) {
          dispatch(
            deleteVoteAsync({
              id: vid,
              stateMan: stateMan,
              userId: user.id ?? 0,
            }),
          );
        }
      });
      setSelectedOption(undefined);
    }

    return (
      <>
        {!hasVoted && !pollHasEnded(pollState) ? (
          <Button
            variant={'primary'}
            onClick={handleVote}
            className={classNames('poll_vote_btn', {})}
            disabled={
              !(pollIsOpen(pollState) || userCanEdit(dialogue)) ||
              !selectedOption ||
              hasVoted
            }
          >
            <FormattedMessage id="POLLS.VOTE" />
          </Button>
        ) : null}
        {hasVoted &&
          ((userCanEdit(dialogue) && !pollHasEnded(pollState)) ||
            (poll?.allowCancellation && pollIsOpen(pollState))) && (
            <Button
              variant={'secondary'}
              onClick={handleCancelVote}
              className={classNames('poll_vote_btn', {})}
              disabled={
                !(pollIsOpen(pollState) || userCanEdit(dialogue)) || !hasVoted
              }
            >
              <FormattedMessage id="POLLS.UNVOTE" />
            </Button>
          )}
      </>
    );
  }

  function PollResults() {
    const resultsCanShow =
      poll &&
      (userCanEdit(dialogue) ||
        (poll.options?.length &&
          (pollHasEnded(pollState) || (hasVoted && poll.showResults))));
    if (!resultsCanShow) return null;
    const sortedOptions = sortByOrder(poll?.options as AnyOrderObject[]);
    const votesCast = sortedOptions.reduce((c, o, i, a) => {
      return c + (o.votes?.length ?? 0);
    }, 0);
    const perc = calculateRoundedPercentages(
      sortedOptions.map((o) => o.votes?.length ?? 0),
    );
    return (
      <>
        {hasVoted || userCanEdit(dialogue) ? (
          <>
            {sortedOptions.map((o: IOption, i: number) => {
              return (
                <div key={o.id} className={'poll_option'}>
                  <div
                    className={'poll_gauge'}
                    style={getGaugeStyle(perc[i])}
                  />
                  <Message
                    message={o.message!}
                    block={block}
                    optionId={o.id}
                    stateMan={props.stateMan}
                    blockRef={scrollableRef}
                    readOnly={true}
                    showLikes={false}
                    showAttribution={false}
                    hideEdited={true}
                    hideTime={true}
                    extraContent={
                      <>
                        {perc[i]}
                        {' % ('}
                        {o.votes?.length}
                        {')'}
                      </>
                    }
                    extraContentClassName={'poll_result'}
                  />
                </div>
              );
            })}
            <div className="poll_option">
              <FormattedMessage
                id="POLLS.VOTESCAST"
                values={{ count: votesCast }}
              />
            </div>
          </>
        ) : (
          <div className="poll_notvoted">
            <FormattedMessage id="POLLS.NOTVOTED" />
          </div>
        )}
      </>
    );
  }

  const listinfo: SortableListInfo = {
    id: block.id as number,
    listType: SortableListType.Options,
    items: options as AnyOrderObject[],
    canvas: false,
  };
  if (!poll) return null;
  return (
    <div
      className={classNames('poll', {
        poll_open: pollState === 'started',
      })}
      ref={scrollableRef}
    >
      {props.showDescription && block.description ? (
        <div className="block_description">{block.description}</div>
      ) : null}
      <PollTiming />
      {!block.locked ? (
        <Collapsable className="poll_voting" dimension="height">
          <Collapsable.Controller
            open={voteSectionOpen}
            setOpen={setVoteSectionOpen}
            className={'poll_hasvoted'}
            // noIcon={true}
            noPlus={true}
            icon={IconSymbol.down}
            closeIcon={IconSymbol.up}
          >
            <div className="poll_voted_header">
              <FormattedMessage
                id={hasVoted ? 'POLLS.VOTED' : 'POLLS.NOTVOTED'}
              />
            </div>
          </Collapsable.Controller>
          <Collapsable.Content open={voteSectionOpen} dimension="height">
            {options && options.length ? (
              <>
                {userCanEdit(dialogue) ? (
                  <div className="poll_voting_section">
                    <SortableContainer reorder={reorderOptions}>
                      <SortableList
                        key={listinfo.id}
                        listInfo={listinfo}
                        accepting={[SortableItemType.Option]}
                        className={'poll_options'}
                        horizontal={false}
                        copySource={false}
                        disabled={!pollIsEditable(poll, pollState)}
                        gap={8}
                      >
                        {sortByOrder(options).map(
                          (option: IOption, i: number) => {
                            const message = option.message;
                            if (!message) return null;
                            return (
                              <Fragment key={option.id}>
                                <SortableItem
                                  className={'poll_option'}
                                  key={option.id}
                                  listInfo={listinfo}
                                  item={option as AnyOrderObject}
                                  itemType={SortableItemType.Option}
                                  index={i}
                                  onCanvas={listinfo.canvas}
                                  withHandle={true}
                                  copySource={false}
                                  // disabled={true}
                                  onClick={() => {
                                    if (!hasVoted)
                                      setSelectedOption(
                                        option ? option.id : undefined,
                                      );
                                  }}
                                  disabled={!pollIsEditable(poll, pollState)}
                                  gap={8}
                                >
                                  <Message
                                    message={message}
                                    block={block}
                                    optionId={option.id}
                                    stateMan={props.stateMan}
                                    blockRef={scrollableRef}
                                    showLikes={false}
                                    showAttribution={false}
                                    readOnly={!pollIsEditable(poll, pollState)}
                                    hideEdited={true}
                                    hideTime={true}
                                    extraContent={
                                      <Vote
                                        option={option}
                                        checked={sameId(option, selectedOption)}
                                        checkOption={(o) => {
                                          setSelectedOption(
                                            o ? o.id : undefined,
                                          );
                                        }}
                                      />
                                    }
                                    extraContentClassName={'poll_vote'}
                                  />
                                </SortableItem>
                              </Fragment>
                            );
                          },
                        )}
                      </SortableList>
                    </SortableContainer>
                    <div className="poll_vote_buttons">
                      <VoteButtons />
                    </div>
                  </div>
                ) : (
                  <div className="poll_voting_section">
                    <div className="poll_options">
                      {sortByOrder(options).map((option: IOption) => {
                        const message = option.message;
                        if (!message) return null;
                        return (
                          <div
                            key={option.id}
                            className="poll_option"
                            onClick={() => {
                              if (pollIsOpen(pollState) && !hasVoted)
                                setSelectedOption(
                                  option ? option.id : undefined,
                                );
                            }}
                          >
                            <Message
                              message={message}
                              block={block}
                              optionId={option.id}
                              stateMan={props.stateMan}
                              blockRef={scrollableRef}
                              showLikes={false}
                              showAttribution={false}
                              hideEdited={true}
                              hideTime={true}
                              extraContent={
                                <Vote
                                  option={option}
                                  checked={sameId(option, selectedOption)}
                                  checkOption={(o) => {
                                    setSelectedOption(o ? o.id : undefined);
                                  }}
                                />
                              }
                              extraContentClassName={'poll_vote'}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="poll_vote_buttons">
                      <VoteButtons />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={'poll_options'}>
                {userCanEdit(dialogue) ? (
                  <FormattedMessage id="POLLS.ADD_OPTIONS" />
                ) : (
                  <FormattedMessage id="POLLS.NO_OPTIONS" />
                )}
              </div>
            )}
          </Collapsable.Content>
        </Collapsable>
      ) : null}
      <div className="poll_results">
        <PollResults />
      </div>
    </div>
  );
}

export function PollBlockToPdf(
  props: {
    block: IBlock;
  } & ExportProps,
) {
  const { dialogue, block, intl } = props;
  const poll = block.childPollBlock?.poll ?? null;
  if (!poll) return null;
  const sortedOptions = sortByOrder(poll?.options as AnyOrderObject[]);
  const votesCast = sortedOptions.reduce((c, o, i, a) => {
    return c + (o.votes?.length ?? 0);
  }, 0);
  const perc = calculateRoundedPercentages(
    sortedOptions.map((o) => o.votes?.length ?? 0),
  );
  return (
    <View style={styles.poll}>
      <View style={styles.poll_results}>
        {sortedOptions.map((o: IOption, i: number) => {
          const gaugeStyle: Style = getGaugeStyle(perc[i], true) as Style;
          return (
            <View key={o.id} style={styles.poll_message_wrapper}>
              <View style={{ ...styles.poll_gauge }}>
                <Svg style={{ ...gaugeStyle }}>
                  <Rect
                    width="1000"
                    height="1000"
                    fill="#ff961e"
                    stroke-width="0"
                  />
                </Svg>
              </View>
              <MessageToPdf
                message={o.message!}
                block={block}
                optionId={o.id}
                readOnly={true}
                showLikes={false}
                showAttribution={false}
                hideEdited={true}
                hideTime={true}
                extraContent={
                  <>
                    {perc[i]}
                    {' % ('}
                    {o.votes?.length}
                    {')'}
                  </>
                }
                extraContentClassName={'poll_result'}
                intl={intl}
                dialogue={dialogue}
              />
            </View>
          );
        })}
        <View style={styles.poll_option}>
          <Text>
            {intl.formatMessage(
              { id: 'POLLS.VOTESCAST' },
              { count: votesCast },
            )}
          </Text>
        </View>
      </View>
    </View>
  );
}

function createGaugeRow(percentage: number, color: string) {
  const gaugeWidth = percentage ? percentage * 6 : 600;
  const gaugeCell = new TableCell({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: createBase64Pixel('ff961e'),
            transformation: {
              width: gaugeWidth,
              height: 8,
            },
            type: 'png',
          }),
        ],
        style: 'gauge',
      }),
    ],
    shading: {
      fill: 'E6E6E6',
      type: ShadingType.CLEAR,
    },
    width: {
      size: `100%`,
      type: WidthType.PERCENTAGE,
    },
    columnSpan: 2,
    margins: {
      left: 0,
      right: 0,
    },
    borders: {
      top: { style: BorderStyle.NONE, size: 0 },
      bottom: { style: BorderStyle.NONE, size: 0 },
      left: { style: BorderStyle.NONE, size: 0 },
      right: { style: BorderStyle.NONE, size: 0 },
    },
  });
  return new TableRow({
    children: [gaugeCell],
  });
}

export async function pollBlockToDocx(
  props: {
    block: IBlock;
  } & ExportProps,
): Promise<ISectionOptions> {
  const promise = new Promise<ISectionOptions>(async (resolve, reject) => {
    const { dialogue, block, intl } = props;
    const poll = block.childPollBlock?.poll ?? null;
    if (!poll || !poll.options)
      return { properties: { type: SectionType.CONTINUOUS }, children: [] };
    const sortedOptions = sortByOrder(poll.options as AnyOrderObject[]);
    const votesCast = sortedOptions.reduce((c, o, i, a) => {
      return c + (o.votes?.length ?? 0);
    }, 0);
    const perc = calculateRoundedPercentages(
      sortedOptions.map((o) => o.votes?.length ?? 0),
    );
    const rows: TableRow[] = [];
    for (const [i, o] of sortedOptions.entries()) {
      const gaugeStyle: Style = getGaugeStyle(perc[i]) as Style;
      const row = await messageToDocx({
        message: o.message!,
        block: block,
        showLikes: false,
        showColours: false,
        showAttribution: false,
        hideTime: true,
        intl,
        dialogue,
      });
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              children: row.cells[0].options.children,
              width: { size: '85%', type: WidthType.PERCENTAGE },
              margins: {
                top: i > 0 ? 12 * 20 : 0,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: gaugeStyle.width?.toString() + ` (${o.votes.length})`,
                  style: 'message',
                }),
              ],
              width: { size: '15%', type: WidthType.PERCENTAGE },
              margins: {
                top: i > 0 ? 12 * 20 : 0,
              },
            }),
          ],
        }),
      );
      rows.push(createGaugeRow(perc[i], 'FF0000'));
    }
    resolve({
      properties: { type: SectionType.CONTINUOUS },
      children: [
        new Table({
          layout: TableLayoutType.FIXED,
          width: { size: '100%', type: WidthType.PERCENTAGE },
          borders: TableBorders.NONE,
          rows: rows,
        }),
        new Paragraph({
          text: intl.formatMessage(
            { id: 'POLLS.VOTESCAST' },
            { count: votesCast },
          ),
          spacing: {
            before: 12 * 20,
          },
        }),
        // table,
      ],
    });
  });
  return promise;
}

const PollBlockContent = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedPollBlockContent),
);

type PollBlockTailProps = {
  poll: IPoll | null;
  pollState: PollState;
  showDescription?: boolean;
  setHasNewMsg: Dispatch<SetStateAction<boolean>>;
  stateMan: StateMan;
};

function UnconnectedPollBlockTail(
  props: PollBlockTailProps & UserStateProps & DialogueStateProps,
) {
  const { poll, pollState, user, userId, setHasNewMsg, userCanEdit, dialogue } =
    props;
  const [chatMsg, setChatMsg] = useState<string>('');
  const [showSend, setShowSend] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [file, setFile] = useState<File | undefined>(undefined); // for uploading image
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const uploadTrigger = useRef<MouseEventHandler | undefined>(undefined);
  const submitting = useRef<boolean>(false);

  useEffect(() => {
    submitting.current = false;
    setShowSend(!justWhitespace(chatMsg) || !!previewSrc);
  }, [chatMsg, previewSrc]);

  function handleMsgChange(input: string, hasText: boolean) {
    if (!submitting.current) {
      setShowSend(hasText);
      setChatMsg(input);
    }
  }

  function submitMessage() {
    if (!(chatMsg || file) || submitting.current) return;
    submitting.current = true;
    if (poll && poll.id && (chatMsg || file)) {
      // update the order attribute
      const order = getOrderUsingIndex(
        sortByOrder(poll.options as AnyOrderObject[]),
        -1, // -1 indicates moving between lists (or adding to a list)
        -1, // -1 indicates adding to the end of the list
      );
      dispatch(
        postOptionAsync({
          data: {
            content: chatMsg,
            edited: false,
            time: new Date(),
            author: userId,
            replyTo: null,
            checked: false,
            color: 'none',
            likes: [],
            replies: [],
            files: [],
          },
          pollId: poll.id,
          order: order,
          author: user,
          file: file,
          stateMan: props.stateMan,
        }),
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 200:
          case 201:
            break;
          default:
            console.log(response, response.status, response?.data?.message);
        }
        setHasNewMsg(true);
        setTimeout(() => {
          setHasNewMsg(false);
        }, 100);
        setChatMsg('');
        setShowSend(false);
        setPreviewSrc(null);
        setFile(undefined);
      });
    }
  }

  return poll && userCanEdit(dialogue) && pollIsEditable(poll, pollState) ? (
    <>
      <FileUpload
        types={imageFileTypes}
        currentSrc={null}
        previewSrc={previewSrc}
        defaultImg={null}
        setPreviewSrc={setPreviewSrc}
        withTrigger={true}
        setFile={setFile}
        onDelete={() => {}}
        className={'tail_container'}
        renderTrigger={(trigger) => (uploadTrigger.current = trigger)}
      >
        <div className="msg_tail">
          <DropMenu right={true} up={true}>
            <DropMenu.Trigger>
              <div className="msg_input_plus" />
            </DropMenu.Trigger>
            <DropMenu.Items>
              <div onClick={uploadTrigger.current}>
                <Icon
                  symbol={IconSymbol.picture}
                  className="msg_input_option"
                />
                <div>
                  <FormattedMessage id="DOCUMENTS.UPLOAD.IMAGE" />
                </div>
              </div>
              {IS_DEV ? (
                <>
                  <div>
                    <Icon
                      symbol={IconSymbol.video}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.VIDEO" />
                    </div>
                  </div>
                  <div>
                    <Icon
                      symbol={IconSymbol.audio}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.AUDIO" />
                    </div>
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
          <div className="msg_input">
            <TextAreaInput
              onChange={handleMsgChange}
              onSubmit={submitMessage}
              placeholder={intl.formatMessage({ id: 'POLLS.ADD_OPTION' })}
              value={chatMsg}
              escapeClears={false}
            />
          </div>
          {showSend ? (
            <div className="msg_input_send" onClick={submitMessage} />
          ) : null}
        </div>
      </FileUpload>
    </>
  ) : (
    <></>
  );
}

const PollBlockTail = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedPollBlockTail),
);
export { PollBlockTail };
