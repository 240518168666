import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';
import { getDialogueStates } from 'features/uiState/uiStateSlice';
import { loginUserAsync } from 'features/user/userSlice';
import { UserForgotFormLink } from './UserForgotForm';

import { useForms } from 'components/forms/Forms';
import { navigateIn } from 'components/navigation/Navigation';
import './UserForm.scss';

export default function UserLoginForm(props: FormProps) {
  const { show, setShow } = props;
  const refSubmitBtn = useRef(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const forms = useForms();
  const { code } = useParams();

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loginUserAsync(data)).then(
          (response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                AsyncAPI.connect();
                resolve();
                const dlgs = getDialogueStates();
                const d = dlgs?.find((dlg) => dlg[1]);
                if (d && d[0]) navigate(`/dialogue/${d[0]}`);
                else {
                  setShow(false);
                  if (code) navigateIn('/join/' + code, 0);
                  else navigate(`/`);
                }
                break;
              case 401:
              default:
                doFormMsg(
                  {
                    message: intl.formatMessage({ id: 'USERS.LOGIN.FAILED' }),
                    success: false,
                  },
                  setFormMsg,
                );
                reject();
                break;
            }
          },
        );
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg,
        );
        console.log('Login request failed for unclear reason.', err);
        reject();
      }
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_form narrow"
      title={<FormattedMessage id="USERS.LOGIN.FORM_TITLE" />}
      onSubmit={handleSubmit}
      noFooter={true}
      refSubmitBtn={refSubmitBtn}
      onCancel={null}
      enableEscape
      formMsg={formMsg}
      delayClose={3000}
    >
      <Form.Group as={Row} controlId="Email">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.LOGIN.EMAIL" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="email"
            name="email"
            autoComplete="username email"
            value={data.email || ''}
            onChange={handleChangeData}
            required
            // pattern={EMAIL_PATTERN}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.LOGIN.EMAIL_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="Password">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.LOGIN.PASSWORD" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="password"
            name="password"
            autoComplete="password"
            value={data.password || ''}
            onChange={handleChangeData}
            required
          />
          {/* <Form.Text className="text-muted instruction"></Form.Text> */}
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.LOGIN.PASSWORD_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <br />
      <Container fluid="md">
        <Form.Group as={Row} className="form">
          <FormMessage {...formMsg} key={formMsg.key} />
          <Button variant="primary wide" ref={refSubmitBtn}>
            <FormattedMessage id="USERS.LOGIN.SUBMIT" />
          </Button>
          <Button
            variant="outline-secondary wide right"
            onClick={() => {
              setShow(false);
              forms.UserSignupForm.setShow(true);
            }}
          >
            <FormattedMessage id="USERS.LOGIN.NEW_USER" />
          </Button>
        </Form.Group>
      </Container>
      <Form.Group as={Row} className="form">
        <Col align={'left'} sm={12}>
          <div className="vspaced">
            <UserForgotFormLink onClick={() => setShow(false)}>
              <FormattedMessage id="USERS.LOGIN.FORGOT" />
            </UserForgotFormLink>
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

export function UserLoginFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, className, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserSignupForm.setShow(false);
    forms.UserLoginForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={classNames('nav_item', className)}>
      {children || <FormattedMessage id="NAVIGATION.LOGIN" />}
    </div>
  );
}
