import {
  BaseLoader,
  BigInteger,
  Constraint,
  distinct,
  distinctmaybe,
  Float,
  Integer,
  It,
  Json,
  many,
  maybe,
  one,
  or,
  Wrapped,
} from './baseLoader.js';

export const baseSchema = (baseLoader: BaseLoader): any => {
  const { Access, AccessProperty, DependsOn } = baseLoader;

  /*
  // Use the following for testing
  @Access((o: IsOkay) => ({ CRUD: o.IsOkay2 }))
  class IsOkay extends It {
    @AccessProperty('admin')
    IsOkay2: Integer = 3;
    @AccessProperty((o: IsOkay) => 'admin')
    IsOkay3 = new IsOkay5();
    @AccessProperty((o: IsOkay5) => o.IsOkay25)
    WrongTypeLambdaParameter = new IsOkay5();
  }
  @Access('*')
  class IsOkay5 extends It {
    IsOkay25: Integer = 3;
  }
  @Access((o: MissingBaseIt) => ({ CRUD: 'hey' }))
  class MissingBaseIt {}
  @Access('*')
  class MissingBaseIt2 {}
  @Access((o: IsOkay) => ({ CRUD: 'hey' }))
  class WrongTypeLambdaParameter extends It {}
  */

  const Status = ['draft', 'private', 'public', 'closed'];

  const rolesInheritance = {
    user: {
      // basic level of users
      inherits: [],
    },
    manager: {
      // managers are users who can moderate any timeline
      inherits: ['user'],
    },
    admin: {
      // admins have full access
      inherits: ['manager'],
    },
  };

  const Roles = Object.keys(rolesInheritance);

  @Access((o: Project) => ({
    delete: or(o.moderators, 'manager'),
    read: or(o.subscribers, o.moderators, 'manager'),
    create: '*',
    update: or(o.moderators, 'manager'),
  }))
  class Project extends It {
    @AccessProperty({ read: '*' })
    title = distinct(String);
    @AccessProperty({ read: '*' })
    subtitle = one(String);
    @AccessProperty({ read: '*' })
    description = one(String);
    @AccessProperty({ read: '*' })
    status = one(Status);
    invitees = many(String);
    @AccessProperty((o: Project) => ({
      read: or(o.moderators, 'manager'),
      update: or(o.moderators, 'manager'),
    }))
    registrationCode = maybe(String);
    @AccessProperty({ createUpdate: '', read: '*' })
    subscribersCount = one(Integer);
    subscribers: Wrapped<User> = many(User, (o) => o.subscribedToProjects);
    moderators: Wrapped<User> = many(User, (o) => o.moderatesProjects);
    @AccessProperty({ read: '*' })
    background: Wrapped<File> = maybe(File, (o) => o.projects);
    userFeedbacks = many(UserFeedback);
    @AccessProperty({ read: '*' })
    dialogues = many(Dialogue);
    files: Wrapped<File> = many(File, (o) => o.projectFiles);
    @AccessProperty({
      createUpdate: '',
    })
    author: Wrapped<User> = one(User, (o) => o.projects);
    @AccessProperty({ read: '*' })
    startedAt = maybe(Date);
    @AccessProperty({ read: '*' })
    closedAt = maybe(Date);
  }

  // a File is considered to be immutable
  @Access((o: File) => ({
    create: '*',
    read: '*',
    update: or(
      o.dialogues,
      o.libraries,
      o.projectFiles,
      o.documentFiles,
      'manager'
    ),
    delete: 'manager',
    // TODO: Use these two lines below, when author access is fixed
    // update: or(o.author, 'manager'),
    // delete: or(o.author, 'manager'),
  }))
  class File extends It {
    description = one(String);
    @AccessProperty({
      read: '*',
      update: 'manager',
    })
    file_path = distinct(String);
    fileName = one(String);
    @AccessProperty({
      createUpdate: '',
    })
    author = one(User);
    uri = distinct(String);
    order = one(String);
    @AccessProperty({ read: '' })
    folder = one(String);
    size = one(Integer);
    mimeType = one(String);
    uploadTime = maybe(Date);

    projects: Wrapped<Project> = many(Project, (o) => o.background);
    dialogues = many(Dialogue);
    avatarsFor = many(User);
    messages: Wrapped<Message> = many(Message, (o) => o.files);
    libraries: Wrapped<LibraryBlock> = many(LibraryBlock, (o) => o.files);
    projectFiles: Wrapped<Project> = many(Project, (o) => o.files);
    documentFiles: Wrapped<Document> = many(Document, (o) => o.files);
  }

  @Access((o: User) => ({
    CRUD: or(o.id, 'admin'),
  }))
  class User extends It {
    subscribedToProjects = many(Project, (o) => o.subscribers);
    moderatesProjects = many(Project, (o) => o.moderators);
    subscribedToDialogues: Wrapped<Dialogue> = many(
      Dialogue,
      (o) => o.subscribers
    );
    moderatesDialogues: Wrapped<Dialogue> = many(Dialogue, (o) => o.moderators);
    @AccessProperty((o: User) => ({
      read: '*',
    }))
    username = one(String);
    email = distinctmaybe(String);
    // @AccessProperty({ createUpdate: 'admin' })
    roles = one(Roles);
    locale = maybe(String);
    @AccessProperty({
      read: 'admin',
      update: 'admin',
      create: '',
    })
    isVerified = one(Boolean);
    @AccessProperty((o: User) => ({
      read: '',
      create: '*',
      update: or(o.id, 'admin'),
    }))
    password = maybe(String); // nobody is allowed to read, password = hash
    @AccessProperty((o: User) => ({
      read: '*',
    }))
    about = one(String);
    @AccessProperty((o: User) => ({
      read: '*',
    }))
    avatar = maybe(File, (o) => o.avatarsFor);
    userFeedbacks = many(UserFeedback);
    votes = many(Vote);
    messages = many(Message);
    likes = many(Like);
    uploadedFiles = many(File);
    dialogues: Wrapped<Dialogue> = many(Dialogue, (o) => o.author);
    projects: Wrapped<Project> = many(Project, (o) => o.author);
    @AccessProperty({ read: '' })
    registrationCode = one(String);
    @AccessProperty({ read: '' })
    guestAccessCode = maybe(String);
    @AccessProperty({ read: '' })
    isGuest = maybe(Boolean);
    @AccessProperty({ read: 'admin', createUpdate: '' })
    logs = many(Log);
    @AccessProperty({ createUpdate: '*' })
    ui = maybe(UiState);
  }

  @Access({
    createUpdate: '*',
    read: 'user',
  })
  class UiState extends It {
    state = one(Json);
    @AccessProperty({ createUpdate: 'user' })
    // this should have been one or better distinct, but this is not accepted because of a prisma generator bug
    user = many(User);
  }

  @Access('*')
  class Like extends It {
    liker = one(User);
    liked = one(Message);
  }

  @Access('*')
  class UserFeedback extends It {
    activity = one(String);
    feedback = one(String);
    project = one(Project);
    user = one(User);
    dialogue = one(Dialogue);
  }

  @Access((o: Dialogue) => ({
    delete: or(o.moderators, 'manager'),
    read: or(o.subscribers, o.moderators, 'manager'),
    create: '*',
    update: or(o.moderators, 'manager'),
  }))
  class Dialogue extends It {
    @AccessProperty({ read: '*' })
    title = one(String);
    @AccessProperty({ read: '*' })
    description = one(String);
    @AccessProperty({ read: '*' })
    goal = one(String);
    @AccessProperty({ read: '*' })
    order = one(String);
    @AccessProperty((o: Dialogue) => ({
      read: '*',
      update: or(o.moderators, 'manager'),
    }))
    status = one(Status);
    @AccessProperty((o: Dialogue) => ({
      read: or(o.moderators, 'manager'),
      update: or(o.moderators, 'manager'),
    }))
    registrationCode = maybe(String);
    @AccessProperty((o: Dialogue) => ({
      read: or(o.moderators, 'manager'),
      update: or(o.moderators, 'manager'),
    }))
    guestAccessCode = maybe(String);
    @AccessProperty((o: Dialogue) => ({
      read: or(o.moderators, 'manager'),
      update: or(o.moderators, 'manager'),
    }))
    allowGuestAccess = maybe(Boolean);
    @AccessProperty({
      createUpdate: '',
    })
    author = one(User, (o) => o.dialogues);
    @AccessProperty({ read: '*' })
    keywords = many(Keyword);
    userFeedbacks = many(UserFeedback);
    project = one(Project);
    subscribers = many(User, (o) => o.subscribedToDialogues);
    @AccessProperty({ createUpdate: '', read: '*' })
    @DependsOn((o: Dialogue) => [o.subscribers])
    subscribersCount = one(Integer);
    moderators = many(User, (o) => o.moderatesDialogues);
    @AccessProperty((o: Dialogue) => ({
      read: '*',
      update: or(o.moderators, 'manager'),
    }))
    background = maybe(File);
    phases = many(Phase);
    @AccessProperty({ read: '*' })
    startedAt = maybe(Date);
    @AccessProperty({ read: '*' })
    closedAt = maybe(Date);
  }

  @Access((o: Phase) => ({
    CRUD: o.dialogue,
  }))
  class Phase extends It {
    order = one(String);
    name = one(String);
    description = one(String);
    dateBegin = maybe(Date);
    dateEnd = maybe(Date);
    dialogue = one(Dialogue);
    blocks = many(Block);
    active = one(Boolean);
    fixed = maybe(Boolean);
  }

  @Access((o: Block) => ({
    CRUD: o.phase,
  }))
  class Block extends It {
    order = one(String);
    name = one(String);
    description = one(String);

    phase = one(Phase);

    childType = one(String);

    childDocumentBlock = maybe(DocumentBlock);
    childPollBlock = maybe(PollBlock);
    childChatBlock = maybe(ChatBlock);
    childListBlock = maybe(ListBlock);
    childLibraryBlock = maybe(LibraryBlock);
    childSudokuBlock = maybe(SudokuBlock);
    // TODO: if at least one child... exists, dan maak child en childType

    locked = maybe(Boolean);
  }

  @Access((o: ListBlock) => ({
    CRUD: o.parent,
  }))
  class ListBlock extends It {
    parent = distinct(Block);

    layout_x = one(Float);
    layout_y = one(Float);
    zoomlevel = one(Integer);
    canvas = one(Boolean);
    editable = one(Boolean);
    @AccessProperty((o: ListBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    likes = one(Boolean);
    snap = one(Boolean);
    grid = one(Boolean);
    @AccessProperty((o: ListBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    checkboxes = one(Boolean);
    @AccessProperty((o: ListBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    colors = one(Boolean);
    @AccessProperty((o: ListBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    attribution = one(Boolean);

    listType = one(String);

    lists = many(List);
  }

  @Access((o: List) => ({
    CRUD: o.parent,
  }))
  class List extends It {
    parent = one(ListBlock);

    name = one(String);
    order = one(String);
    coordinate_x = one(Float);
    coordinate_y = one(Float);

    listItems = many(ListItem);
  }

  @Access((o: ListItem) => ({
    create: o.list,
    update: o.list,
    read: o.list,
    delete: 'user',
  }))
  class ListItem extends It {
    @AccessProperty((o: ListItem) => ({
      read: o.list,
      update: '*',
    }))
    list = one(List);
    @AccessProperty((o: ListItem) => ({
      read: o.list,
      update: '*',
    }))
    coordinate_x = one(Float);
    @AccessProperty((o: ListItem) => ({
      read: o.list,
      update: '*',
    }))
    coordinate_y = one(Float);
    @AccessProperty((o: ListItem) => ({
      read: o.list,
      update: '*',
    }))
    order = one(String);
    @AccessProperty((o: ListItem) => ({
      read: o.list,
      update: '*',
    }))
    alt = maybe(String);

    pinned = maybe(Boolean);
    message = distinct(Message);
  }

  @Access((o: DocumentBlock) => ({
    CRUD: o.parent,
  }))
  class DocumentBlock extends It {
    parent = distinct(Block);

    document = maybe(Document);
    readOnly = one(Boolean);
  }

  @Access((o: Document) => ({
    CRUD: o.block,
  }))
  class Document extends It {
    block = distinctmaybe(DocumentBlock);

    @AccessProperty((o: Document) => ({
      read: o.block,
      update: 'user',
    }))
    text = one(Json);

    files: Wrapped<File> = many(File, (o) => o.documentFiles);
  }

  @Access((o: PollBlock) => ({
    CRUD: o.parent,
  }))
  class PollBlock extends It {
    parent = distinct(Block);

    poll = maybe(Poll);
  }

  @Access((o: Poll) => ({
    CRUD: o.parent,
  }))
  class Poll extends It {
    parent = distinct(PollBlock);

    start = maybe(Date);
    end = maybe(Date);
    closed = one(Boolean);
    options = many(Option);
    showResults = one(Boolean);
    allowCancellation = one(Boolean);

    // TODO:
    // pollresults read: .poll.votes.voter & user | manager
  }

  @Access((o: Option) => ({
    CRUD: o.poll,
  }))
  class Option extends It {
    poll = one(Poll);
    votes = many(Vote);
    message = distinct(Message);

    order = one(String);
  }

  @Access((o: Vote) => ({
    CRUD: o.option,
  }))
  class Vote extends It {
    voted = one(Date);
    option = one(Option);
    voter = one(User);
  }

  @Access((o: ChatBlock) => ({
    CRUD: o.parent,
  }))
  class ChatBlock extends It {
    parent = distinct(Block);

    messages = many(Message);
  }

  @Access((o: Message) => ({
    read: or(
      o.chatBlockContainer,
      o.libraryBlockContainer,
      o.listItem,
      o.option,
      o.replyTo?.listItem
    ),
    // TODO: fix later
    create: 'user',
    update: 'user',
    delete: 'user',
    // TODO: Use these three lines below, when author access is fixed
    // read: or(o.chatBlockContainer, o.listItem),
    // createUpdate: or(o.author, o.chatBlockContainer, o.listItem),
    // delete: or(o.author, o.chatBlockContainer, o.listItem),

    // TODO: Use these three lines below, when Poll feature is implemented
    // read: or(o.chatBlockContainer, o.listItem, o.option),
    // createUpdate: or(o.author, o.chatBlockContainer, o.listItem, o.option),
    // delete: or(o.author, o.chatBlockContainer, o.listItem, o.option),
  }))
  class Message extends It {
    chatBlockContainer = maybe(ChatBlock);
    libraryBlockContainer = maybe(LibraryBlock);
    content = one(String);
    edited = one(Boolean);
    time = one(Date);
    color = one(String);
    checked = one(Boolean);

    author = one(User);
    likes = many(Like);

    replyTo: Wrapped<Message> = maybe(Message, (o) => o.replies);
    replies: Wrapped<Message> = many(Message, (o) => o.replyTo);

    listItem = maybe(ListItem);
    option = maybe(Option);

    files: Wrapped<File> = many(File, (o) => o.messages);
  }

  @Access((o: LibraryBlock) => ({
    CRUD: o.parent,
  }))
  class LibraryBlock extends It {
    parent = distinct(Block);

    files: Wrapped<File> = many(File, (o) => o.libraries);
    links = many(Message);
  }

  @Access((o: SudokuBlock) => ({
    CRUD: o.parent,
  }))
  class SudokuBlock extends It {
    parent = distinct(Block);

    solution = one(String);
    @AccessProperty((o: SudokuBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    challenge = one(String);
    @AccessProperty((o: SudokuBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    attempt = one(String);
    @AccessProperty((o: SudokuBlock) => ({
      read: o.parent,
      update: 'user',
    }))
    hints = maybe(Integer);
  }

  @Access('*')
  class Keyword extends It {
    text = one(String);
    dialogue = maybe(Dialogue);
  }

  @Access((o: Session) => ({
    create: '*',
  }))
  class Session extends It {
    // TODO: return a meaningfull Id, or change generated OpenAP
    Email = one(String);
    @Constraint((str: String) => str.length > 5)
    Password = one(String);
  }

  @Access((o: Account) => ({
    update: '*',
  }))
  class Account extends It {
    Email = one(String);
    @Constraint((str: String) => str.length > 5)
    Password = one(String);
    Token = one(String);
    RegistrationCode = one(String);
  }

  @Access((o: Subscription) => ({
    update: '*',
  }))
  class Subscription extends It {
    ProjectId = one(Integer);
    DialogueId = one(Integer);
    Subscribe = one(Boolean);
    RegistrationCode = one(String);
  }

  /*
    #GET admin(): { type: { msg: { type: string } }, read: admin, createUpdate: "" }
    #POST resend():
    #  headers: [verifyToken]
    #  body: ...
    #POST reset(): { }
    #POST forgot(): { }
    #POST login(): { }
    #POST:
    #  resend()
  */

  @Access({
    createUpdate: '*',
    read: 'admin',
  })
  class Log extends It {
    message = one(String);
    timestamp = one(Date);
    @AccessProperty({ createUpdate: '' })
    user = one(User);
  }

  @Access('admin')
  class DBLog extends It {
    table_name = one(String);
    old_row_data = maybe(Json);
    new_row_data = maybe(Json);
    dml_type = one(DBLogType);
    dml_timestamp = one(Date);
  }

  @Access('admin')
  class ApplicationLog extends It {
    message = one(Json);
    timestamp = distinct(BigInteger);
  }

  // Access set on all, for now.
  // TODO: Discuss later.
  @Access('*')
  class MeetingLog extends It {
    start = one(Date);
    end = one(Date);
    folderName = one(String);
    recordingName = one(String);
  }

  @Access({
    createUpdate: 'admin',
    read: '*',
  })
  class ApplicationSettings extends It {
    tools = maybe(Json);
    rights = maybe(Json);
    maxmbs = maybe(Json);
  }

  const DBLogType = ['INSERT', 'UPDATE', 'DELETE'];

  return {
    enums: { Status, Roles, DBLogType },
    rolesInheritance,
    classes: [
      ApplicationLog,
      ApplicationSettings,
      Project,
      File,
      User,
      UiState,
      Like,
      UserFeedback,
      Dialogue,
      Phase,
      Block,
      ListBlock,
      ListItem,
      List,
      DocumentBlock,
      ChatBlock,
      LibraryBlock,
      SudokuBlock,
      Document,
      PollBlock,
      Poll,
      Option,
      Vote,
      Message,
      Keyword,
      Session,
      Subscription,
      Log,
      Account,
      DBLog,
      MeetingLog,
    ],
  };
};
