import classNames from 'classnames';
import { shuffleArray } from 'helpers/arrays';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const ImageGrid: React.FC<{}> = () => {
  const [gridImages, setGridImages] = useState<string[]>([]);
  const [prevIndex, setPrevIndex] = useState<number>(-1);
  const [swappingIndices, setSwappingIndices] = useState<number[]>([]);
  const refTimer = useRef<NodeJS.Timeout>();

  const swap = useCallback(() => {
    refTimer.current = setTimeout(() => {
      if (gridImages.length) {
        let index1 = Math.floor(Math.random() * gridImages.length);
        while (index1 === prevIndex || gridImages[index1] === '') {
          index1 = Math.floor(Math.random() * gridImages.length);
        }
        let index2 = Math.floor(Math.random() * gridImages.length);
        while (index2 === prevIndex || gridImages[index2] !== '') {
          index2 = Math.floor(Math.random() * gridImages.length);
        }
        setSwappingIndices([index1]);
        setPrevIndex(index1);
        setGridImages((prevGridImages) => {
          const newGridImages = [...prevGridImages];
          newGridImages[index2] = prevGridImages[index1];
          if (prevIndex >= 0) newGridImages[prevIndex] = '';
          return newGridImages;
        });
      }
    }, Math.random() * 3000);
  }, [gridImages, prevIndex]);

  useEffect(() => {
    if (!gridImages.length) {
      let imgs: string[] = [
        'bench_deliberating.jpg',
        'co-creation_session_park.jpg',
        'co-creation_with_screen.jpg',
        'demonstration.jpg',
        'discussion_at_table.jpg',
        'formal_conversation.jpg',
        'haagse_wijk.jpg',
        'henno_presenting.jpg',
        'meeting_in_church.jpg',
        'meeting_in_park.jpg',
        'online_meeting_1.jpg',
        'online_meeting_2.jpg',
        'people_deliberating.jpg',
        'people_discussing_in_street.jpg',
        'people_in_event.jpg',
        'people_listening.jpg',
        'people_meeting.jpg',
        'people_on_bike.jpg',
        'prinsjesdag.jpg',
        'speakers_corner.jpg',
        'spui_bouwplaats.jpg',
        'stadhuis_den_haag.jpg',
        'street.jpg',
      ];
      const blancs: string[] = Array.from({ length: 14 }, () => '');
      setGridImages(shuffleArray([...imgs, ...blancs]));
    } else swap();
    return () => clearTimeout(refTimer.current);
  }, [gridImages.length, swap]);

  return (
    <div className="bg_grid">
      {gridImages.map((img, i) => (
        <div
          key={img + i}
          className={classNames('bg_img_bg', {
            // bg_img_bg_w: (swappingIndices.includes(i) || img === '') && i > 15,
          })}
        >
          <div
            className={classNames('bg_img', {
              bg_img_blanc: swappingIndices.includes(i) || img === '',
            })}
            style={
              img === ''
                ? {}
                : {
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/sq/${img})`,
                  }
            }
          ></div>
        </div>
      ))}
    </div>
  );
};

export default ImageGrid;
