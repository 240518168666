import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { ModeratorIcon } from 'components/admin/ModeratorIcon';
import Collapsable from 'components/collapsable/Collapsable';
import FormattedMessage from 'components/formatters/FormattedMessage';
import Autocomplete from 'components/forms/Autocomplete';
import Icon, { IconSymbol } from 'components/icons/Icon';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IProject } from 'features/projects/projectsAPI';
import {
  IUser,
  fetchUserNames,
  fetchUserNamesForProject,
} from 'features/user/userAPI';
import {
  UserStateProps,
  getAuthorName,
  mapUserStateToProps,
} from 'features/user/userSlice';
import { getColorForId } from 'helpers/colors';
import { AnyIdObject, sameId } from 'helpers/objects';
import { sortBy } from 'helpers/sorting';

type ParticipantsProps = {
  subscribers: IUser[];
  setSubscribers?: Dispatch<SetStateAction<IUser[]>>;
  moderators?: IUser[];
  setModerators?: Dispatch<SetStateAction<IUser[]>>;
  project?: IProject;
  editing?: boolean;
  noLabel?: boolean;
  onChange?: (
    subscribers: IUser[] | undefined,
    moderators: IUser[] | undefined
  ) => void;
  showId?: boolean;
};

function UnconnectedParticipants(
  props: ParticipantsProps & UserStateProps & DialogueStateProps
) {
  const {
    subscribers, // undefined for not showing subscribers
    setSubscribers, // undefined for not modifying subscribers
    moderators, // undefined for not showing moderators
    setModerators, // undefined for not modifying moderators
    project,
    editing, // allow editing
    noLabel = false, // if true, only shows the count, not the 'participants' label
    onChange, // pings the caller that a change was made
    showId = false,
    userCanEdit,
    user,
    userIsManager,
    authors,
  } = props;
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  useEffect(() => {
    async function getUsers(projectId?: number) {
      try {
        // NOTE:
        // if projectId is given, we are looking at the participants of a dialogue
        // if projectId is not given, we are looking at the participants of a project
        const res = projectId
          ? await fetchUserNamesForProject(projectId)
          : await fetchUserNames();
        switch (res.status) {
          case 200:
            setUsers(res.data);
            break;
        }
      } catch {
        console.log('Error fetching user info.');
      }
    }
    getUsers(project?.id);
    setCanEdit(!!editing && (project ? userCanEdit(project) : userIsManager));
  }, [editing, project, userCanEdit, userIsManager]);

  function onCompleteSubscriber(completedInput: string, id?: number) {
    if (!subscribers || !setSubscribers) return;
    // find the user with the given id
    const user = users.find((u) => sameId(u, id));
    // add the input to the list of subscribers
    if (user && subscribers.filter((u) => sameId(u, user)).length === 0) {
      setSubscribers([...subscribers, user]);
      onChange && onChange([...subscribers, user], moderators);
    }
  }

  function removeSubscriber(id: number) {
    if (!subscribers || !setSubscribers) return;
    const newSubscr = subscribers.filter((u) => u.id !== id);
    setSubscribers(newSubscr);
    let newMods;
    if (setModerators) {
      newMods = (moderators ?? []).filter((u) => u.id !== id);
      setModerators(newMods);
    }
    onChange && onChange(newSubscr, newMods);
  }

  function handleModerationChange(user: IUser, checked: boolean) {
    if (!moderators || !setModerators) return;
    const newMods = checked
      ? !moderators.some((u) => sameId(u, user))
        ? [...moderators, user]
        : moderators
      : moderators.filter((s) => !sameId(s.id, user.id));
    setModerators(newMods);
    onChange && onChange(subscribers, newMods);
  }

  function usersMissing() {
    if (users.length)
      return subscribers?.reduce((m: boolean, s: IUser) => {
        return !users.some((u) => sameId(u, s));
      }, false);
    return false;
  }
  return (
    <>
      {
        <Collapsable className="user_list" dimension="height">
          <Collapsable.Controller
            open={open}
            setOpen={setOpen}
            className={classNames({
              label: !noLabel,
              user_missing: usersMissing(),
            })}
            noIcon={true}
            // noPlus={true}
          >
            {noLabel ? (
              subscribers?.length
            ) : (
              <FormattedMessage
                id="DIALOGUE.VIEW.SUBSCRIBER_COUNT"
                values={{ count: subscribers?.length }}
              />
            )}
            {moderators ? (
              <>
                {' ('}
                {noLabel ? null : (
                  <>
                    <FormattedMessage id="X.OFWHICH" />{' '}
                  </>
                )}
                {noLabel ? (
                  moderators.length
                ) : (
                  <FormattedMessage
                    id="DIALOGUE.VIEW.MODERATOR_COUNT"
                    values={{ count: moderators.length }}
                  />
                )}
                {')'}
              </>
            ) : null}
          </Collapsable.Controller>
          <Collapsable.Content open={open} dimension="height">
            {subscribers ? (
              <>
                {sortBy(subscribers, 'username').map((subscriber, i) => {
                  const color = getColorForId(
                    subscriber.id,
                    subscribers.map((s) => s.id ?? 0)
                  );
                  return (
                    <div key={i} className="user_item">
                      <div
                        className={classNames('user_name', {
                          user_missing: !users.some((u) =>
                            sameId(u, subscriber)
                          ),
                          blurInDemo: true,
                        })}
                        style={{ color: color }}
                      >
                        {showId ? '[' + subscriber.id + '] ' : null}
                        {getAuthorName(subscriber, authors)}
                      </div>
                      {setSubscribers && canEdit ? (
                        <Icon
                          symbol={IconSymbol.bin}
                          className="user_remove"
                          onClick={() => removeSubscriber(subscriber.id)}
                        />
                      ) : null}
                      {moderators ? (
                        <ModeratorIcon
                          user={subscriber}
                          moderators={moderators}
                          onChange={
                            editing &&
                            (userIsManager || !sameId(user, subscriber))
                              ? handleModerationChange
                              : undefined
                          }
                        />
                      ) : null}
                    </div>
                  );
                })}
                {setSubscribers && canEdit && users ? (
                  <>
                    <Autocomplete
                      suggestionList={(users as AnyIdObject[]).filter(
                        (u) => !subscribers.map((s) => s.id).includes(u.id)
                      )}
                      attribute="username"
                      onComplete={onCompleteSubscriber}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </Collapsable.Content>
        </Collapsable>
      }
    </>
  );
}

const Participants = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedParticipants)
);
export default Participants;
