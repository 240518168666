import { ReactNode, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Status } from '@thuas/pd-schemas';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { copyLink, getLink } from 'helpers/helpers';
import { useForms } from './Forms';

function UnconnectedSharingCodesForm(
  props: FormProps & UserStateProps & DialogueStateProps
) {
  const { show, setShow, dialogue } = props;
  const intl = useIntl();
  const [copied, setCopied] = useState<boolean>(false);
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);

  useEffect(() => {
    if (show) {
      doFormMsg(NoFormMsg, setFormMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, dialogue]);

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      editing={false}
      className=""
      title={<FormattedMessage id="PROJECTS.SHARING.LINK" />}
      // onSubmit={null}
      onCancel={null}
      enableEscape
      formMsg={formMsg}
    >
      <Form.Group as={Row}>
        <Form.Text>
          <FormattedMessage id={'PROJECTS.SHARING.INSTR'} />
        </Form.Text>
      </Form.Group>
      {dialogue.project?.registrationCode ? (
        <Form.Group as={Row} className="last_row">
          <Form.Label column sm="12" md="12" lg="12" className="label">
            <FormattedMessage id="PROJECTS.SHARING.PROJECT" />
          </Form.Label>
          <Col sm={12} md={12} lg={12}>
            <div className="regcode">
              {getLink(`signup/${dialogue.project?.registrationCode}`)}
              <Icon
                className="reg_access_link"
                symbol={IconSymbol.copy}
                hoverVariant={IconVariant.dark}
                onClick={() => {
                  copyLink(
                    `signup/${dialogue.project?.registrationCode}`,
                    setCopied
                  );
                }}
                hintProps={{
                  hint: intl.formatMessage({
                    id: copied
                      ? 'PROJECTS.SHARING.COPIEDLINK'
                      : 'PROJECTS.SHARING.COPYLINK',
                  }),
                  offset: { x: 20, y: 20 },
                  offsetRight: true,
                }}
                size={16}
                inline
              />
              <Icon
                className="reg_access_link"
                symbol={IconSymbol.share}
                hoverVariant={IconVariant.dark}
                onClick={() => {
                  const shd = {
                    url: `signup/${dialogue.project?.registrationCode}`,
                  };
                  navigator.share(shd);
                }}
                hintProps={{
                  hint: intl.formatMessage({
                    id: 'PROJECTS.SHARING.SHARELINK',
                  }),
                  offset: { x: 20, y: 20 },
                  offsetRight: false,
                }}
                size={16}
                inline
              />
            </div>
          </Col>
        </Form.Group>
      ) : null}
      {dialogue.status !== Status.public && dialogue.registrationCode ? (
        <>
          <Form.Group as={Row} className="last_row">
            <Form.Label column sm="12" md="12" lg="12" className="label">
              <FormattedMessage id="PROJECTS.SHARING.DIALOGUE" />
            </Form.Label>
            <Col sm={12} md={12} lg={12}>
              <div className="regcode">
                {getLink(`signup/${dialogue.registrationCode}`)}
                <Icon
                  className="reg_access_link"
                  symbol={IconSymbol.copy}
                  hoverVariant={IconVariant.dark}
                  onClick={() => {
                    copyLink(`signup/${dialogue.registrationCode}`, setCopied);
                  }}
                  hintProps={{
                    hint: intl.formatMessage({
                      id: copied
                        ? 'PROJECTS.SHARING.COPIEDLINK'
                        : 'PROJECTS.SHARING.COPYLINK_DIALOGUE',
                    }),
                    offset: { x: 20, y: 20 },
                    offsetRight: true,
                  }}
                  size={16}
                  inline
                />
                <Icon
                  className="reg_access_link"
                  symbol={IconSymbol.share}
                  hoverVariant={IconVariant.dark}
                  onClick={() => {
                    const shd = {
                      url: `signup/${dialogue.registrationCode}`,
                    };
                    navigator.share(shd);
                  }}
                  hintProps={{
                    hint: intl.formatMessage({
                      id: 'PROJECTS.SHARING.SHARELINK_DIALOGUE',
                    }),
                    offset: { x: 20, y: 20 },
                    offsetRight: false,
                  }}
                  size={16}
                  inline
                />
              </div>
            </Col>
          </Form.Group>
          {dialogue.allowGuestAccess && dialogue.guestAccessCode ? (
            <Form.Group as={Row} className="last_row">
              <Form.Label column sm="12" md="12" lg="12" className="label">
                <FormattedMessage id="PROJECTS.SHARING.GUESTS" />
              </Form.Label>
              <Col sm={12} md={12} lg={12}>
                <div className="regcode">
                  {getLink(`guest/${dialogue.guestAccessCode}`)}
                  <Icon
                    className="reg_access_link"
                    symbol={IconSymbol.copy}
                    hoverVariant={IconVariant.dark}
                    onClick={() => {
                      copyLink(`guest/${dialogue.guestAccessCode}`, setCopied);
                    }}
                    hintProps={{
                      hint: intl.formatMessage({
                        id: copied
                          ? 'PROJECTS.SHARING.COPIEDLINK'
                          : 'PROJECTS.SHARING.COPYLINK_GUESTS',
                      }),
                      offset: { x: 20, y: 20 },
                      offsetRight: true,
                    }}
                    size={16}
                    inline
                  />
                  <Icon
                    className="reg_access_link"
                    symbol={IconSymbol.share}
                    hoverVariant={IconVariant.dark}
                    onClick={() => {
                      const shd = {
                        url: `guest/${dialogue.guestAccessCode}`,
                      };
                      navigator.share(shd);
                    }}
                    hintProps={{
                      hint: intl.formatMessage({
                        id: 'PROJECTS.SHARING.SHARELINK_GUESTS',
                      }),
                      offset: { x: 20, y: 20 },
                      offsetRight: false,
                    }}
                    size={16}
                    inline
                  />
                </div>
              </Col>
            </Form.Group>
          ) : null}
        </>
      ) : null}
      <br />
    </ModalDialog>
  );
}

const SharingCodesForm = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedSharingCodesForm)
);
export default SharingCodesForm;

export function SharingCodesFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  asButton?: boolean;
  children?: ReactNode;
}) {
  const { onClick, className, asButton = false, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.SharingCodesForm.setShow(true);
    onClick && onClick(e);
  }

  return asButton ? (
    <Button onClick={handleClick} className={className}>
      {children || <FormattedMessage id="PROJECTS.SHARING.LINK" />}
    </Button>
  ) : (
    <div onClick={handleClick} className={className}>
      {children || <FormattedMessage id="PROJECTS.SHARING.LINK" />}
    </div>
  );
}
