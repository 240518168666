import { UsePDFInstance } from '@react-pdf/renderer';
import { Paragraph } from 'docx';
import { IntlShape } from 'react-intl';

import { IDialogue } from 'features/dialogue/dialogueAPI';

export type ExportProps = {
  intl: IntlShape;
  dialogue: IDialogue;
};

export async function downloadPdf(pdf: UsePDFInstance, filename: string) {
  if (!pdf.url || pdf.loading) return;
  try {
    const fn = sanitizeFileName(filename);
    const response = await fetch(pdf.url);
    if (!response.ok) throw new Error('Network response was not ok');
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fn;
    document.body.appendChild(link);
    link.click();

    if (document && document.body && link && document.body.contains(link)) {
      try {
        document.body.removeChild(link);
      } catch (error) {
        console.error('An error occurred while removing the child:', error);
      }
    } else {
      console.warn('Child element not found within parent.');
    }

    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error fetching or downloading the PDF:', error);
  }
}

export function sanitizeFileName(input?: string): string {
  if (!input) return 'Download';
  const accentMap: { [key: string]: string } = {
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    Ø: 'O',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ø: 'o',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    Ç: 'C',
    ç: 'c',
    Ñ: 'N',
    ñ: 'n',
    Ý: 'Y',
    ý: 'y',
    ÿ: 'y',
  };
  // Replace accented characters with their unaccented equivalents
  const noAccents = input
    .split('')
    .map((char) => accentMap[char] || char)
    .join('');
  const regex = /[^a-zA-Z0-9-_. ]/g;
  return noAccents.replace(regex, '');
}

export function datesToDocx(
  intl: IntlShape,
  start?: Date | null,
  end?: Date | null,
): Paragraph | null {
  if (start)
    return new Paragraph({
      text:
        intl.formatDate(start, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }) +
        (end
          ? ' - ' +
            intl.formatDate(end, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
          : ''),
      style: 'date',
    });
  return null;
}
