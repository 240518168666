import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import {
  doFormMsg,
  FormMessage,
  FormMsg,
  NoFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';
import { navigateIn } from 'components/navigation/Navigation';
import {
  fetchUserAsync,
  mapUserStateToProps,
  UserStateProps,
  verifyMailAsync,
} from 'features/user/userSlice';
import { connect } from 'react-redux';

function UnconnectedUserVerifyMailForm(props: FormProps & UserStateProps) {
  const { show, setShow, userId, userLoggedIn } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  const { token, regcode } = useParams();
  // const forms = useForms();

  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  // we use the token parameter from the url to receive the token (see App.js)
  useEffect(() => {
    if (!show) return;
    if (token)
      dispatch(verifyMailAsync({ token: token, regcode: regcode })).then(
        (response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 204:
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: userLoggedIn
                      ? 'USERS.CONFIRM.SUCCESS_2'
                      : 'USERS.CONFIRM.SUCCESS',
                  }),
                  success: true,
                },
                setFormMsg,
              );
              dispatch(fetchUserAsync(userId));
              navigateIn('/', 5000);
              // navigateIn('/login', 10000, undefined);
              // , () => {
              //   setShow(false);
              // });
              break;
            default:
              doFormMsg(
                {
                  message: intl.formatMessage({ id: 'USERS.CONFIRM.FAILED' }),
                  success: false,
                },
                setFormMsg,
              );
              break;
          }
        },
      );
    else {
      setShow(false);
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, show]);

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      resolve();
      setShow(false);
      navigateIn('/', 0);
    });
  }

  return (
    <ModalDialog
      show={show && !!token}
      setShow={setShow}
      className="user_form narrow"
      title={<FormattedMessage id="USERS.CONFIRM.TITLE" />}
      onSubmit={handleSubmit} // undefined shows the button, but takes no extra action.
      editing={false}
      onCancel={null}
      enableEscape
      delayClose={20000}
    >
      <p>
        <FormattedMessage id="USERS.CONFIRM.TEXT" />
      </p>
      <FormMessage {...formMsg} key={formMsg.key} />
    </ModalDialog>
  );
}

const UserVerifyMailForm = connect()(
  connect(mapUserStateToProps)(UnconnectedUserVerifyMailForm),
);
export default UserVerifyMailForm;
