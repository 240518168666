import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { store } from 'app/store';
import { IconSymbol } from 'components/icons/Icon';
import { maxUploadSizeAllowed } from 'features/admin/appSettingsSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { IProject } from 'features/projects/projectsAPI';
import { IUser } from 'features/user/userAPI';
import { IFile, IFileCreate, deleteFile, patchFile, postFile } from './fileAPI';
// import { MimeTypes } from './mimetypes';

export enum AcceptedMimeType {
  // image = 'image/*',
  gif = 'image/gif',
  jpg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pdf = 'application/pdf',
  unknown = '',
}

export type restrictedImageTypes = 'jpg' | 'png';
export type imageTypes = restrictedImageTypes | 'svg';
export type docTypes = 'doc' | 'docx' | 'xls' | 'xlsx' | 'ppt' | 'pptx' | 'pdf';
export type fileTypes = imageTypes | docTypes | 'url';

export type AcceptedFileType = {
  type: fileTypes;
  mime: AcceptedMimeType;
  ext: string[];
  size: number;
  icon: IconSymbol;
};

const jpg: AcceptedFileType = {
  type: 'jpg',
  mime: AcceptedMimeType.jpg,
  ext: ['.jpg', '.jpeg'],
  size: 3_000_000,
  icon: IconSymbol.mime_img,
};
const png: AcceptedFileType = {
  type: 'png',
  mime: AcceptedMimeType.png,
  ext: ['.png'],
  size: 3_000_000,
  icon: IconSymbol.mime_img,
};
const svg: AcceptedFileType = {
  type: 'svg',
  mime: AcceptedMimeType.svg,
  ext: ['.svg'],
  size: 3_000_000,
  icon: IconSymbol.mime_img,
};
const doc: AcceptedFileType = {
  type: 'doc',
  mime: AcceptedMimeType.doc,
  ext: ['.doc'],
  size: 10_000_000,
  icon: IconSymbol.mime_docx,
};
const docx: AcceptedFileType = {
  type: 'doc',
  mime: AcceptedMimeType.docx,
  ext: ['.docx'],
  size: 10_000_000,
  icon: IconSymbol.mime_docx,
};
const xls: AcceptedFileType = {
  type: 'xls',
  mime: AcceptedMimeType.xls,
  ext: ['.xls'],
  size: 10_000_000,
  icon: IconSymbol.mime_xlsx,
};
const xlsx: AcceptedFileType = {
  type: 'xls',
  mime: AcceptedMimeType.xlsx,
  ext: ['.xlsx'],
  size: 10_000_000,
  icon: IconSymbol.mime_xlsx,
};
const ppt: AcceptedFileType = {
  type: 'ppt',
  mime: AcceptedMimeType.ppt,
  ext: ['.ppt'],
  size: 20_000_000,
  icon: IconSymbol.mime_pptx,
};
const pptx: AcceptedFileType = {
  type: 'ppt',
  mime: AcceptedMimeType.pptx,
  ext: ['.pptx'],
  size: 20_000_000,
  icon: IconSymbol.mime_pptx,
};
const pdf: AcceptedFileType = {
  type: 'pdf',
  mime: AcceptedMimeType.pdf,
  ext: ['.pdf'],
  size: 20_000_000,
  icon: IconSymbol.mime_pdf,
};
const linkType: AcceptedFileType = {
  type: 'url',
  mime: AcceptedMimeType.unknown,
  ext: ['.???'],
  size: 0,
  // icon: IconSymbol.mime_unknown,
  icon: IconSymbol.link,
};

export function typesAllowed(types: AcceptedFileType[]): string {
  return types.flatMap((t) => t.ext).join(', ');
}
export function sizeAllowed(types: AcceptedFileType[], type: string): number {
  const acc = types.find((t) => {
    const re = new RegExp(`^${t.mime.replace('*', '.*')}$`);
    return re.test(type);
  });
  if (acc && acc.ext.length > 0) {
    // try to get max from app settings
    const s = maxUploadSizeAllowed(acc.type);
    if (s >= 0) return s;
    return acc.size; // use default
  }
  return 0;
}

export const restrictedImageFileTypes = [jpg, png];
export const imageFileTypes = [...restrictedImageFileTypes, svg];
export const docFileTypes = [doc, docx, xls, xlsx, ppt, pptx, pdf];
export const allFileTypes = [...restrictedImageFileTypes, ...docFileTypes];

export function getImageTypeInfo(type: string): AcceptedFileType | null {
  const acc = imageFileTypes.find((t) => {
    const re = new RegExp(`^${t.mime.replace('*', '.*')}$`);
    return re.test(type ?? '');
  });
  return acc ?? null;
}
export function isImageType(type: string): boolean {
  let isImg = type === 'img';
  if (isImg) return true;
  return imageFileTypes.some((t) => t.type === type || t.mime === type);
}
export function getFileTypeInfo(file: IFile | File | string): AcceptedFileType {
  let acc: AcceptedFileType | undefined;
  if (typeof file === 'string') {
    acc = allFileTypes.find((t) => {
      return file === t.type;
    });
  } else {
    const type = file instanceof File ? file.type : file.mimeType;
    acc = allFileTypes.find((t) => {
      const re = new RegExp(`^${t.mime.replace('*', '.*')}$`);
      return re.test(type ?? '');
    });
  }
  if (acc) return acc;
  if (typeof file === 'string' && isImageType(file))
    return restrictedImageFileTypes[0];
  return linkType;
}

export enum FolderLocation {
  Project,
  Dialogue,
  Avatars,
}
export function getFolderName(
  location: FolderLocation,
  folder?: string,
  id?: number
): string {
  const dlg: IDialogue = store.getState().dialogue?.dialogue;
  const proj: IProject = id
    ? store.getState().projects?.projects?.find((p: IProject) => p.id === id)
    : undefined;
  let fname;
  switch (location) {
    case FolderLocation.Dialogue:
      if (dlg) fname = `Projects/${dlg.project?.id}/Dialogues/${dlg.id}`;
      break;
    case FolderLocation.Project:
      if (dlg && dlg.id && dlg.project && dlg.project.id)
        fname = `Projects/${dlg.project?.id}`;
      else if (proj) fname = `Projects/${proj.id}`;
      break;
    case FolderLocation.Avatars:
      fname = `Avatars`;
      break;
  }
  if (!fname) fname = 'Uncategorised';
  return `${fname}/${folder ?? ''}`;
}

export const postFileAsync = createAsyncThunk(
  'file/postFile',
  async (
    {
      data,
      file,
      author,
      stateMan,
    }: {
      stateMan: StateMan;
      data: IFileCreate;
      file: File;
      author: IUser;
    },
    thunkAPI
  ) => {
    try {
      const res = await postFile(file, { ...data, uploadTime: new Date() });
      return { response: res, stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchFileAsync = createAsyncThunk(
  'file/patchFile',
  async (
    {
      data,
      stateMan,
    }: {
      data: IFile;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchFile(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteFileAsync = createAsyncThunk(
  'file/deleteFile',
  async (
    {
      file,
      stateMan,
    }: {
      file: IFile;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await deleteFile(file.id as number), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addFileCases(builder: ActionReducerMapBuilder<IDialogueState>) {
  builder
    .addCase(patchFileAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchFileAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state); // library
      const file = stateObj.hasOwnProperty('fileName')
        ? stateObj
        : stateObj.hasOwnProperty('files')
        ? stateObj.files.find((f: IFile) => f.id === action.meta.arg.data.id)
        : null;
      if (file) Object.assign(file, action.meta.arg.data);
      else
        console.log(
          "Oops, your update got lost, but no worries - we've saved already."
        );
    })
    .addCase(patchFileAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postFileAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postFileAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const files = action.meta.arg.data.libraries?.length
        ? stateObj.hasOwnProperty('files')
          ? stateObj.files
          : null
        : state.dialogue.project?.files;
      if (files) {
        const id = action.payload.response.data._id;
        const data = {
          ...action.meta.arg.data,
          author: action.meta.arg.author,
          id: id,
          uri: action.payload.response.data.message,
          folder: undefined,
        };
        files.push(data);
      } else
        console.log(
          "Oops, your file got lost, but no worries - we've saved already."
        );
    })
    .addCase(postFileAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteFileAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteFileAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      let files = stateObj.hasOwnProperty('files') ? stateObj.files : null;
      if (files && files.some((f: IFile) => f.id === action.meta.arg.file.id)) {
        files.splice(
          files.findIndex((f: IFile) => f.id === action.meta.arg.file.id),
          1
        );
        return;
      } else {
        files = state.dialogue.project?.files;
        if (files) {
          files.splice(
            files.findIndex((f: IFile) => f.id === action.meta.arg.file.id),
            1
          );
          return;
        }
      }
      console.log(
        'Oops, your file got lost, but no worries - it has been deleted already.'
      );
    })
    .addCase(deleteFileAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });

  // .addCase(asyncApiMessagesNew.fulfilled, (state, action) => {})
  // .addCase(asyncApiMessagesUpdated.fulfilled, (state, action) => {
  //   const payload = action.meta.arg.data;

  //   const stateObj = action.meta.arg.stateMan(state);
  //   const messages = stateObj.messages;

  //   const rootUpdated = payload.updated;

  //   if (deepSearchKey(rootUpdated, 'messages')) {
  //     const payloadMessages = rootUpdated.messages;

  //     const isLikes = deepSearchKeyType(
  //       payloadMessages,
  //       'likes',
  //       'array',
  //       true
  //     );

  //     if (!isLikes && deepSearchKey(payloadMessages, 'new')) {
  //       for (const newMessage of payloadMessages.new) {
  //         if (!arraySomeById(messages, newMessage.id)) {
  //           messages.push(newMessage);
  //         }
  //       }
  //     }

  //     if (deepSearchKey(payloadMessages, 'updated')) {
  //       for (const updatedMessage of payloadMessages.updated) {
  //         const messageIndex = findIndexById(messages, updatedMessage.id);

  //         if (isLikes) {
  //           const updatedLikes = updatedMessage.updated.likes;

  //           if (deepSearchKey(updatedLikes, 'new')) {
  //             for (const like of updatedLikes.new) {
  //               if (!arraySomeById(messages[messageIndex].likes, like.id)) {
  //                 messages[messageIndex].likes.push(like);
  //               }
  //             }
  //           }
  //           if (deepSearchKey(updatedLikes, 'removed')) {
  //             for (const like of updatedLikes.removed) {
  //               const likeIndex = findIndexById(
  //                 messages[messageIndex].likes,
  //                 like
  //               );

  //               if (likeIndex !== -1) {
  //                 messages[messageIndex].likes.splice(likeIndex, 1);
  //               }
  //             }
  //           }
  //         } else {
  //           messages[messageIndex] = updateObject(
  //             messages[messageIndex],
  //             updatedMessage.updated
  //           );
  //         }
  //       }
  //     }

  //     if (!isLikes && deepSearchKey(payloadMessages, 'removed')) {
  //       for (const deletedMessage of payloadMessages.removed) {
  //         const messageIndex = findIndexById(messages, deletedMessage);

  //         if (messageIndex !== -1) {
  //           messages.splice(messageIndex, 1);
  //         }
  //       }
  //     }
  //   }
  // })
  // .addCase(asyncApiMessagesRemoved.fulfilled, (state, action) => {});
}
