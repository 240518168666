import { IS_DEV } from 'helpers/consts';
import { onCLS, onINP, onLCP } from 'web-vitals';

export default function reportWebVitals() {
  if (IS_DEV) {
    onCLS(console.log);
    onINP(console.log);
    onLCP(console.log);
  }
}
