import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export type FormMsg = {
  message: string;
  success: boolean;
  timeout?: number;
  key?: number;
};
export const NoFormMsg: FormMsg = {
  message: '',
  success: false,
  timeout: 0,
  key: undefined,
};
export function doFormMsg(
  m: FormMsg,
  setter: Dispatch<SetStateAction<FormMsg>>,
) {
  setter((prev) => {
    return { ...m, key: m.key !== undefined ? m.key : (prev.key ?? 0) + 1 };
  });
}

export function FormMessage(
  props: FormMsg & { setter?: Dispatch<SetStateAction<FormMsg>> },
) {
  const { message, success, timeout = 0, setter } = props;
  const [msg, setMsg] = useState<string>('');
  const [scs, setScs] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const refTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    setShow(true);
    setMsg(message);
    setScs(success);
    if (timeout)
      refTimer.current = setTimeout(() => {
        setShow(false);
        setMsg('');
        setScs(false);
        setter && setter(NoFormMsg);
      }, timeout);
    return () => {
      clearTimeout(refTimer.current);
    };
  }, [message, success, timeout, setter]);
  if (show && msg && msg !== '') {
    return (
      <div
        className={'alert ' + (scs ? 'alert-success' : 'alert-danger')}
        role="alert"
      >
        {msg}
      </div>
    );
  }
  return null;
}
