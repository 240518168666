import classNames from 'classnames';
import {
  ChangeEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';
import { navigateIn } from 'components/navigation/Navigation';
import {
  ISignupGuestData,
  UserStateProps,
  mapUserStateToProps,
  signupGuestAsync,
  signupUserAsync,
} from 'features/user/userSlice';
import { PASSWORD_PATTERN, SLUGS, USERNAME_PATTERN } from 'helpers/consts';
import { makeCSV, pathStartsWith } from 'helpers/helpers';
import { UserLoginFormLink } from './UserLoginForm';

import Collapsable from 'components/collapsable/Collapsable';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { Link, useParams } from 'react-router-dom';

import { useForms } from 'components/forms/Forms';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { useRandomName } from 'helpers/randomName';
import '../forms/WizardForm.scss';
import './UserForm.scss';

function UnconnectedUserSignupForm(
  props: FormProps & UserStateProps & AppSettingsStateProps,
) {
  const { show, setShow, userId, settings } = props;
  const refSubmitBtn = useRef(null);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const emptyData = {
    username: '',
    email: '',
    password: '',
    registrationCode: '',
  };
  const [data, setData] = useState(emptyData);
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);
  const [method, setMethod] = useState<'regcode' | 'noregcode' | undefined>(
    undefined,
  );
  const [userType, setUserType] = useState<'guest' | 'user' | undefined>(
    undefined,
  );
  const [signupType, setSignupType] = useState<'guest' | 'user' | undefined>(
    undefined,
  );
  const { code } = useParams();
  const guestTryoutAccessCode = settings.rights.tryoutCode ?? '';
  const getRandomName = useRandomName();
  const [consent, setConsent] = useState<boolean>(false);
  const [showStep, setShowStep] = useState<number>(1);
  const [validForm, setValidForm] = useState<boolean>(true);
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);

  useEffect(() => {
    if (show) {
      setData(emptyData);
      setMethod(undefined);
      setUserType(undefined);
      if (pathStartsWith(SLUGS.GUEST)) {
        setUserType('guest');
        setSignupType('guest');
      } else if (pathStartsWith(SLUGS.SIGNUP)) {
        setUserType('user');
        setSignupType('user');
      }
      if (
        code &&
        !hasRegistered &&
        (pathStartsWith(SLUGS.GUEST) || pathStartsWith(SLUGS.SIGNUP))
      ) {
        if (userId) {
          navigateIn('/join/' + code, 0);
        }
        setMethod('regcode');
        setData((prev) => {
          return { ...prev, registrationCode: code };
        });
      }
      doFormMsg(NoFormMsg, setFormMsg);
      setHasRegistered(false);
      setConsent(false);
      setValidForm(true);
      setShowStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, userId]);

  function formatRegCode(regcode: string): string {
    if (!regcode) return '';
    return makeCSV(regcode);
  }

  const renameAtt = (att: string): string => {
    switch (att) {
      case 'name':
        return 'username';
    }
    return att;
  };

  const handleChangeData = (e: SyntheticEvent) => {
    doFormMsg(NoFormMsg, setFormMsg);
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [renameAtt(target.name)]: target.value,
    });
  };

  function handleCancel() {
    setData(emptyData);
    return true;
  }

  async function handleSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (hasRegistered) {
          resolve();
          setShow(false);
          if (code === guestTryoutAccessCode) navigateIn('/tryout', 0);
          else navigateIn('/', 0);
        } else {
          data.username = data.username.trim();
          if (userType === 'user') {
            data.registrationCode = formatRegCode(data.registrationCode);
            dispatch(signupUserAsync(data)).then(
              (response: { payload: any }) => {
                switch (response.payload?.response.status) {
                  case 201:
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.SUCCESS_VER',
                        }),
                        success: true,
                      },
                      setFormMsg,
                    );
                    setHasRegistered(true);
                    navigateIn('/', 40000);
                    resolve();
                    break;
                  case 202:
                    const incorrectRegcodes: string =
                      response?.payload?.response?.data?.message?.split(
                        ': ',
                      )?.[1];
                    doFormMsg(
                      {
                        message: `${intl.formatMessage({
                          id: 'USERS.SIGNUP.SUCCESS_VER_REGCODE_LIST',
                        })} ${incorrectRegcodes}`,
                        success: true,
                      },
                      setFormMsg,
                    );
                    setHasRegistered(true);
                    navigateIn('/', 40000);
                    resolve();
                    break;
                  case 406:
                    setValidForm(false);
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.FAILED_CODE',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                  case 409:
                    setValidForm(false);
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.EMAIL_IN_USE',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                  case 500:
                  default:
                    setValidForm(false);
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.FAILED',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                }
              },
            );
          } else {
            const guestData: ISignupGuestData = {
              username: data.username,
              guestAccessCode: data.registrationCode,
            };
            dispatch(signupGuestAsync(guestData)).then(
              (response: { payload: any }) => {
                switch (response.payload?.response.status) {
                  case 201:
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.SUCCESS',
                        }),
                        success: true,
                      },
                      setFormMsg,
                    );
                    setHasRegistered(true);
                    // dispatch(
                    //   fetchDialogueIdsAsync(response.payload.response.data._id)
                    // )
                    //   .then((dialogueId) => {
                    //     console.log('navigating');
                    //     navigateIn(
                    //       dialogueId === settings.rights.tryoutDialogue
                    //         ? '/tryout'
                    //         : `/dialogue/${
                    //             (dialogueId as any).payload.response
                    //           }`,
                    //       0
                    //     );
                    //     resolve();
                    //   })
                    //   .catch((error) => {
                    //     console.log('Error:', error);
                    //     navigateIn('/', 0);
                    //     reject();
                    //   });
                    if (code === guestTryoutAccessCode)
                      navigateIn('/tryout', 0);
                    resolve();
                    break;
                  // case 202:
                  //   const incorrectRegcodes: string =
                  //     response?.payload?.response?.data?.message?.split(': ')?.[1];
                  //   doFormMsg(
                  //     {
                  //       message: `${intl.formatMessage({
                  //         id: 'USERS.SIGNUP.SUCCESS_VER_REGCODE_LIST',
                  //       })} ${incorrectRegcodes}`,
                  //       success: true,
                  //     },
                  //     setFormMsg
                  //   );
                  //   setHasRegistered(true);
                  //   navigateIn('/', 40000);
                  //   resolve();
                  //   break;
                  case 406:
                    setValidForm(false);
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.FAILED_CODE',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                  case 409:
                    setValidForm(false);
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.EMAIL_IN_USE',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                  case 500:
                  default:
                    doFormMsg(
                      {
                        message: intl.formatMessage({
                          id: 'USERS.SIGNUP.FAILED',
                        }),
                        success: false,
                      },
                      setFormMsg,
                    );
                    reject();
                    break;
                }
              },
            );
          }
        }
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg,
        );
        console.log('Signup request failed for unclear reason.', err);
        reject();
      }
    });
  }

  function handleMethodCheck(event: ChangeEvent<HTMLInputElement>) {
    setMethod(event.target.name === 'regcode' ? 'regcode' : 'noregcode');
    if (event.target.name === 'noregcode') setUserType('user');
  }

  function handleUserTypeCheck(event: ChangeEvent<HTMLInputElement>) {
    setUserType(event.target.name === 'guest' ? 'guest' : 'user');
  }

  function handleConsentCheck(event: ChangeEvent<HTMLInputElement>) {
    setConsent(event.target.checked);
  }

  function completed(): number {
    if (data.username?.length < 6) return 0;
    if (!method) return 1;
    if (method === 'regcode' && data.registrationCode?.length < 6) return 1;
    if (!userType) return 2;
    if (
      userType === 'user' &&
      (data.email.length < 8 || data.password.length < 8)
    )
      return 2;
    if (consent) return 4;
    return 3;
  }

  let step = 1;
  // if (next) {
  //   // consent given
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_form user_signup wizard_form"
      title={<FormattedMessage id="USERS.SIGNUP.FORM_TITLE" />}
      onSubmit={handleSubmit}
      noFooter={true}
      refSubmitBtn={refSubmitBtn}
      onCancel={handleCancel}
      // enableEscape
      formMsg={formMsg}
      delayClose={40000}
      setValidForm={setValidForm}
    >
      {/* User name */}
      <Collapsable dimension="height">
        <Collapsable.Controller
          open={!validForm || showStep === 1}
          setOpen={() => {
            if (showStep !== 1) setShowStep(1);
          }}
          noIcon={false}
          symbol={step++}
          noPlus={!validForm || showStep === 1}
        >
          {showStep !== 1 && data.username ? (
            <FormattedMessage
              id="USERS.SIGNUP.WELCOME"
              values={{ name: data.username }}
            />
          ) : (
            <FormattedMessage id={'USERS.SIGNUP.NAME_INSTR'} />
          )}
        </Collapsable.Controller>
        <Collapsable.Content
          open={!validForm || showStep === 1}
          dimension="height"
        >
          <Form.Group as={Row} controlId="Displayname">
            <Form.Label column sm="12" md="12" lg="12">
              <FormattedMessage id="USERS.SIGNUP.NAME" />
            </Form.Label>
            <Col sm={12} md={12} lg={12}>
              <Row className="subrow">
                <Form.Control
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={data.username || ''}
                  onChange={handleChangeData}
                  required
                  pattern={USERNAME_PATTERN}
                />
                {userType === 'guest' ? (
                  <Button
                    className="right"
                    variant={'outline-secondary'}
                    size={'sm'}
                    onClick={() => {
                      setData({ ...data, username: getRandomName() });
                    }}
                  >
                    <FormattedMessage id="USERS.GUESTS.GIVE_NAME" />
                  </Button>
                ) : null}
                {validForm ? (
                  <Button
                    className="right"
                    variant={
                      data.username?.length < 6
                        ? 'outline-secondary'
                        : 'secondary'
                    }
                    size={'sm'}
                    onClick={() => {
                      if (data.username)
                        setShowStep(
                          (prev) =>
                            prev +
                            (method ? (userType === 'guest' ? 3 : 2) : 1),
                        );
                    }}
                    disabled={data.username?.length < 6}
                  >
                    <FormattedMessage id="X.OK" />
                  </Button>
                ) : null}
                <Form.Text className="text-muted instruction">
                  <FormattedMessage id="USERS.SIGNUP.NAME_HINT" />
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id="USERS.SIGNUP.NAME_FDBCK" />
                </Form.Control.Feedback>
              </Row>
            </Col>
          </Form.Group>
        </Collapsable.Content>
      </Collapsable>
      {/* Reg code */}
      {validForm && code ? null : ( // no need to bother with reg code
        <Collapsable dimension="height">
          <Collapsable.Controller
            open={!validForm || showStep === 2}
            setOpen={() => {
              if (showStep !== 2 && data.username) setShowStep(2);
            }}
            noIcon={false}
            symbol={step++}
            noPlus={!validForm || showStep === 2}
            disabled={completed() < 1}
          >
            <FormattedMessage id={'USERS.SIGNUP.REGCODE_INSTR'} />{' '}
            {method && showStep !== 2 ? (
              method === 'regcode' ? (
                <FormattedMessage id={'X.YES'} />
              ) : (
                <FormattedMessage id={'X.NO'} />
              )
            ) : null}
          </Collapsable.Controller>
          <Collapsable.Content
            open={!validForm || showStep === 2}
            dimension="height"
          >
            <Form.Group as={Row}>
              <Col sm={12} md={12} lg={12}>
                <Row className="subrow">
                  <Form.Group controlId="noregcode">
                    <Form.Check
                      className="text novalidation"
                      name="noregcode"
                      checked={method === 'noregcode'}
                      onChange={handleMethodCheck}
                      type={'radio'}
                      label={intl.formatMessage({
                        id: 'USERS.SIGNUP.WITHOUT_REGCODE',
                      })}
                    />
                  </Form.Group>
                  <Form.Group controlId="regcode">
                    <Form.Check
                      className="text novalidation"
                      name="regcode"
                      checked={method === 'regcode'}
                      onChange={handleMethodCheck}
                      type={'radio'}
                      label={intl.formatMessage({
                        id: 'USERS.SIGNUP.WITH_REGCODE',
                      })}
                    />
                  </Form.Group>
                  {method === 'regcode' ? (
                    <Form.Control
                      type="text"
                      name="registrationCode"
                      value={data.registrationCode || ''}
                      onChange={handleChangeData}
                      required={method === 'regcode'}
                      placeholder={intl.formatMessage({
                        id: 'USERS.SIGNUP.REGCODE_PLCH',
                      })}
                      minLength={3}
                    />
                  ) : null}
                  {validForm ? (
                    <Button
                      className="right"
                      variant={
                        !method ||
                        (method === 'regcode' &&
                          data.registrationCode?.length < 6)
                          ? 'outline-secondary'
                          : 'secondary'
                      }
                      size={'sm'}
                      onClick={() => {
                        if (method === 'noregcode' || data.registrationCode)
                          setShowStep((prev) => prev + 1);
                      }}
                      disabled={
                        !method ||
                        (method === 'regcode' &&
                          data.registrationCode?.length < 6)
                      }
                    >
                      <FormattedMessage id="X.OK" />
                    </Button>
                  ) : null}
                </Row>
              </Col>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              <FormattedMessage id="USERS.SIGNUP.REGCODE_FDBCK" />
            </Form.Control.Feedback>
          </Collapsable.Content>
        </Collapsable>
      )}
      {/* Email + Pwd */}
      <Collapsable dimension="height">
        <Collapsable.Controller
          open={!validForm || showStep === 3}
          setOpen={() => {
            if (showStep !== 3 && data.username) setShowStep(3);
          }}
          noIcon={false}
          symbol={step++}
          noPlus={!validForm || showStep === 3}
          disabled={completed() < 2}
        >
          {method === 'regcode' ? (
            <>
              <FormattedMessage id={'USERS.GUESTS.INSTR'} />{' '}
              {showStep !== 3 && userType ? (
                userType === 'guest' ? (
                  <FormattedMessage id="USERS.GUESTS.GUEST" />
                ) : (
                  <FormattedMessage id="USERS.GUESTS.USER" />
                )
              ) : null}
            </>
          ) : (
            <FormattedMessage id={'USERS.SIGNUP.USER_INSTR'} />
          )}
        </Collapsable.Controller>
        <Collapsable.Content
          open={!validForm || showStep === 3}
          dimension="height"
        >
          {method === 'regcode' ? (
            <Form.Group as={Row}>
              <Col sm={12} md={12} lg={12}>
                <Row className="subrow">
                  <Form.Group controlId="guest">
                    <Form.Check
                      className="text novalidation"
                      name="guest"
                      checked={userType === 'guest'}
                      onChange={handleUserTypeCheck}
                      type={'radio'}
                      label={intl.formatMessage({ id: 'USERS.GUESTS.GUEST' })}
                      disabled={signupType === 'user'}
                    />
                  </Form.Group>
                  <Form.Group controlId="user">
                    <Form.Check
                      className="text novalidation"
                      name="user"
                      checked={userType === 'user'}
                      onChange={handleUserTypeCheck}
                      type={'radio'}
                      label={intl.formatMessage({
                        id: 'USERS.GUESTS.USER',
                      })}
                    />
                  </Form.Group>
                  {userType === 'guest' && validForm ? (
                    <Button
                      className="right"
                      variant={
                        !method ||
                        (method === 'regcode' &&
                          data.registrationCode?.length < 6)
                          ? 'outline-secondary'
                          : 'secondary'
                      }
                      size={'sm'}
                      onClick={() => {
                        if (data.registrationCode)
                          setShowStep((prev) => prev + 1);
                      }}
                      disabled={
                        !method ||
                        (method === 'regcode' &&
                          data.registrationCode?.length < 6)
                      }
                    >
                      <FormattedMessage id="X.OK" />
                    </Button>
                  ) : null}
                </Row>
              </Col>
            </Form.Group>
          ) : null}
          {userType === 'user' ? (
            <>
              <Form.Group as={Row} controlId="Email">
                <Form.Label column sm="12" md="12" lg="12">
                  <FormattedMessage id="USERS.SIGNUP.EMAIL_INSTR" />
                </Form.Label>
                <Col sm={12} md={12} lg={12}>
                  <Row className="subrow">
                    <Form.Control
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={data.email || ''}
                      onChange={handleChangeData}
                      required
                      // pattern={EMAIL_PATTERN}
                    />
                    <Form.Text className="text-muted instruction">
                      <FormattedMessage id="USERS.SIGNUP.EMAIL_HINT" />
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      <FormattedMessage id="USERS.SIGNUP.EMAIL_FDBCK" />
                    </Form.Control.Feedback>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="Password">
                <Form.Label column sm="12" md="12" lg="12">
                  <FormattedMessage id="USERS.SIGNUP.PASSWORD_INSTR" />
                </Form.Label>
                <Col sm={12} md={12} lg={12}>
                  <Row className="subrow">
                    <Form.Control
                      type="password"
                      name="password"
                      autoComplete="password"
                      value={data.password || ''}
                      onChange={handleChangeData}
                      required
                      pattern={PASSWORD_PATTERN}
                    />
                    {validForm ? (
                      <Button
                        className="right"
                        variant={
                          data.password?.length < 8
                            ? 'outline-secondary'
                            : 'secondary'
                        }
                        size={'sm'}
                        onClick={() => {
                          if (data.password?.length)
                            setShowStep((prev) => prev + 1);
                        }}
                        disabled={data.password?.length < 8}
                      >
                        <FormattedMessage id="X.OK" />
                      </Button>
                    ) : null}
                    <Form.Text className="text-muted instruction">
                      <FormattedMessage id="USERS.SIGNUP.PASSWORD_HINT" />
                    </Form.Text>
                    <Form.Control.Feedback className="feedback" type="invalid">
                      <FormattedMessage id="USERS.SIGNUP.PASSWORD_FDBCK" />
                    </Form.Control.Feedback>
                  </Row>
                </Col>
              </Form.Group>
            </>
          ) : null}
        </Collapsable.Content>
      </Collapsable>
      {/* Consent */}
      <Collapsable dimension="height">
        <Collapsable.Controller
          open={!validForm || showStep === 4}
          setOpen={() => {
            if (showStep !== 4 && data.username) setShowStep(4);
          }}
          noIcon={false}
          symbol={step++}
          noPlus={!validForm || showStep === 4}
          disabled={completed() < 3}
        >
          <FormattedMessage id={'USERS.SIGNUP.SUBMIT_INSTR'} />
        </Collapsable.Controller>
        <Collapsable.Content
          open={!validForm || showStep === 4}
          dimension="height"
        >
          <Container fluid="md">
            <Form.Group as={Row} controlId="consent" className="consent">
              <Form.Check
                className="text novalidation"
                name="consent"
                checked={consent}
                onChange={handleConsentCheck}
                type={'checkbox'}
                label={
                  <>
                    <FormattedMessage id="USERS.CONSENT.CONSENT" />{' '}
                    <Link to="/consent" target="_blank" className="underlined">
                      <FormattedMessage id={'USERS.CONSENT.CONSENT_LINK'} />
                    </Link>{' '}
                    <Icon
                      symbol={IconSymbol.new_window}
                      size={14}
                      variant={IconVariant.dark}
                      inline
                    />
                  </>
                }
              />
            </Form.Group>
            <Form.Group as={Row} className="form">
              <FormMessage {...formMsg} key={formMsg.key} />
              <Button
                variant="primary wide"
                ref={refSubmitBtn}
                disabled={completed() < 4}
              >
                <FormattedMessage
                  id={
                    hasRegistered
                      ? 'X.OK'
                      : userType === 'user'
                        ? 'USERS.SIGNUP.SUBMIT'
                        : 'USERS.GUESTS.SUBMIT'
                  }
                />
              </Button>
              {hasRegistered ? null : (
                <Button
                  variant="outline-secondary wide right"
                  onClick={() => {
                    setShow(false);
                  }}
                  // disabled={hasRegistered}
                >
                  <FormattedMessage id="X.CANCEL" />
                </Button>
              )}
            </Form.Group>
          </Container>
        </Collapsable.Content>
      </Collapsable>
      <Form.Group as={Row} className="form">
        <Col align={'left'} sm={12}>
          <div className="vspaced">
            <UserLoginFormLink onClick={() => setShow(false)}>
              <FormattedMessage id="USERS.SIGNUP.HAVE_ACCOUNT" />
            </UserLoginFormLink>
          </div>
          <div className="vspaced">
            <FormattedMessage id="USERS.CONSENT.CONSENT_READ" />{' '}
            <Link to="/consent" target="_blank">
              <FormattedMessage id={'USERS.CONSENT.CONSENT_LINK'} />
            </Link>{' '}
            <Icon
              symbol={IconSymbol.new_window}
              size={14}
              variant={IconVariant.dark}
              inline
            />
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const UserSignupForm = connect(mapUserStateToProps)(
  connect(mapAppSettingsStateToProps)(UnconnectedUserSignupForm),
);
export default UserSignupForm;

export function UserSignupFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, className, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserLoginForm.setShow(false);
    forms.UserSignupForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={classNames('nav_item', className)}>
      {children || <FormattedMessage id="NAVIGATION.SIGNUP" />}
    </div>
  );
}
