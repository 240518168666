import classNames from 'classnames';
import { nl } from 'date-fns/locale/nl';
import { inSameYear, isTimeZero, onSameDay } from 'helpers/datetime';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import {
  FormattedTime,
  FormattedDate as IntlFormattedDate,
  useIntl,
} from 'react-intl';
import { IDateFormatting, removeYear } from './FormattedDate';
import TimeInput from './TimeInput';

import 'react-datepicker/dist/react-datepicker.css';
import './PeriodPicker.scss';

registerLocale('nl', nl);

type PeriodPickerProps = {
  from: Date | null | undefined; // The from date.
  setFrom?: (date: Date | null) => void; // Callback to set from date
  to: Date | null | undefined; // The to date.
  setTo?: (date: Date | null) => void; // Callback to set to date
  separator?: string | JSX.Element; // Content to use as separator between times.
  showDate?: boolean; // Show date even if from and to are on the same day.
  showYear?: boolean; // Show year even if from and to are in the same year.
  showTime?: boolean; // Show time even if it is 00:00.
  hideTime?: boolean; // Hide time at all times.
  format?: IDateFormatting; // Object to describe the date/time formatting.
  editing?: boolean; // If true, allow picking new dates.
  labels?: PeriodPickerLabels; // Object to insert labels before and after each date.
};
type PeriodPickerLabels = {
  from?: {
    before?: string | JSX.Element;
    after?: string | JSX.Element;
  };
  to?: {
    before?: string | JSX.Element;
    after?: string | JSX.Element;
  };
};
/**
 * Formats a period of time, possibly over days.
 *
 * @param {PeriodPickerProps} props
 * @returns {JSX.Element} The formatted content as JSX.Element.
 */
export default function PeriodPicker(props: PeriodPickerProps): JSX.Element {
  const intl = useIntl();
  const {
    setFrom,
    setTo,
    separator,
    showDate,
    showYear = true,
    showTime,
    hideTime,
    editing,
    labels = {},
  } = props;
  const sep = separator || '\u00A0\u2014\u00A0';
  // if (!props.from || !props.to)
  //   return (
  //     <div>
  //       <FormattedMessage id="GENERAL.FORM.NODATE" />
  //     </div>
  //   );
  // const from = year1970toNull(props.from);
  // const to = year1970toNull(props.to);
  const from = props.from ? new Date(props.from) : null;
  const to = props.to ? new Date(props.to) : null;
  const nonulls = !!from && !!to;
  const format: IDateFormatting = props.format || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const format1 = { ...format };
  if (!showYear && nonulls && inSameYear(from, to)) removeYear(format1);
  const format2 = { ...format };
  if (!showYear && nonulls && inSameYear(new Date(), to)) removeYear(format2);

  function handleDateChange(date: Date | null, field: string) {
    switch (field) {
      case 'from':
        setFrom && setFrom(date);
        break;
      case 'to':
        setTo && setTo(date);
        if (!from && setFrom && date) setFrom(new Date());
        break;
    }
  }

  // type DatePickerButtonProps = {
  //   value?: Date;
  //   format?: IDateFormatting;
  //   onClick?: () => void;
  // };
  // const DatePickerButton = forwardRef<HTMLDivElement, DatePickerButtonProps>(
  //   ({ value, onClick }, ref: Ref<HTMLDivElement>) => (
  //     <div className="datepicker_button" onClick={onClick} ref={ref}>
  //       {value ? <IntlFormattedDate value={value} {...format} /> : null}
  //     </div>
  //   )
  // );

  // type TimePickerButtonProps = {
  //   value?: Date;
  //   onClick?: () => void;
  // };
  // const TimePickerButton = forwardRef<HTMLDivElement, TimePickerButtonProps>(
  //   ({ value, onClick }, ref: Ref<HTMLDivElement>) => (
  //     <div className="timepicker_button" onClick={onClick} ref={ref}>
  //       {value?.toString()}
  //     </div>
  //   )
  // );

  if (!editing && !nonulls) return <></>;
  if (!editing && nonulls && onSameDay(from, to)) {
    return (
      <>
        {labels.from?.before ?? null}
        {!!showDate ? (
          <div className="date">
            <IntlFormattedDate value={from} {...format1} />
          </div>
        ) : null}
        {hideTime ? (
          <></>
        ) : (
          <div className="timespan">
            <div className="from">
              <div className="time">
                <FormattedTime value={from} hour="2-digit" minute="2-digit" />
              </div>
            </div>
            {from.getTime() === to.getTime() ? null : (
              <>
                <div className="date_sep">{sep}</div>
                <div className="to">
                  <div className="time">
                    <FormattedTime value={to} hour="2-digit" minute="2-digit" />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {labels.from?.after ?? null}
      </>
    );
  }
  const showTimes =
    !hideTime && nonulls && (showTime || !isTimeZero(from) || !isTimeZero(to));
  return (
    <div className="datespan">
      <div className="from">
        {labels.from?.before ?? null}
        <div className="date_time">
          <div
            className={classNames('date', {
              'as-form-control': editing,
            })}
          >
            {editing ? (
              <ReactDatePicker
                selected={from}
                onChange={(date: Date | null) => handleDateChange(date, 'from')}
                // customInput={React.createElement(DatePickerButton, {
                //   format: format1,
                // })}
                popperPlacement={'bottom'}
                // popperModifiers={[{ name: 'flip', enabled: false }]}
                locale={nl}
                dateFormat={'dd MMM yyyy'}
                isClearable={true}
                clearButtonClassName={'datepicker_clear'}
              />
            ) : from ? (
              <IntlFormattedDate value={from} {...format1} />
            ) : null}
          </div>
          {showTimes || editing ? (
            <div
              className={classNames('time', {
                'as-form-control': editing,
              })}
            >
              {editing ? (
                <TimeInput
                  date={from}
                  onChange={(date: Date) => handleDateChange(date, 'from')}
                />
              ) : from ? (
                <FormattedTime value={from} hour="2-digit" minute="2-digit" />
              ) : null}
            </div>
          ) : null}
        </div>
        {labels.from?.after ?? null}
      </div>
      <div className="date_sep">{sep}</div>
      <div className="to">
        {labels.to?.before ?? null}
        <div className="date_time">
          <div
            className={classNames('date', {
              'as-form-control': editing,
            })}
          >
            {editing ? (
              <ReactDatePicker
                selected={to}
                onChange={(date: Date | null) => handleDateChange(date, 'to')}
                // customInput={React.createElement(DatePickerButton, {
                //   format: format2,
                // })}
                popperPlacement={'bottom'}
                // popperModifiers={[{ name: 'flip', enabled: false }]}
                locale={nl}
                dateFormat={'dd MMM yyyy'}
                isClearable={true}
                clearButtonClassName={'datepicker_clear'}
              />
            ) : to ? (
              <IntlFormattedDate value={to} {...format2} />
            ) : null}
          </div>
          {showTimes || editing ? (
            <div
              className={classNames('time', {
                'as-form-control': editing,
              })}
            >
              {editing ? (
                <TimeInput
                  date={to}
                  onChange={(date: Date) => handleDateChange(date, 'to')}
                />
              ) : // )
              to ? (
                <FormattedTime value={to} hour="2-digit" minute="2-digit" />
              ) : null}
            </div>
          ) : null}
        </div>
        {labels.to?.after ?? null}
      </div>
    </div>
  );
}

// function year1970toNull(date: string | Date | undefined | null): Date | null {
//   if (!date) return null;
//   const d = new Date(date);
//   return d.getFullYear() === new Date(1970, 1, 1).getFullYear() ? null : d;
// }

// export function nullTo1970(date: Date | null): Date {
//   return date ?? new Date(1970, 1, 1);
// }
