import classNames from 'classnames';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import Navigation from 'components/navigation/Navigation';
import {
  AdminStateProps,
  mapAdminStateToProps,
} from 'features/admin/adminSlice';
import {
  AppSettingsStateProps,
  fetchAppSettingsAsync,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { setLocale } from 'features/intl/intlSlice';
import {
  IDecodedResponse,
  UserStateProps,
  fetchUserAsync,
  mapUserStateToProps,
  setUserId,
} from 'features/user/userSlice';
import Forms, { FormContextProvider } from '../forms/Forms';

type LayoutProps = {
  children?: ReactNode;
  bg?: boolean;
  white?: boolean;
  restricted?: boolean;
  fixed?: boolean;
};

function UnconnectedLayout(
  props: LayoutProps & UserStateProps & AdminStateProps & AppSettingsStateProps,
) {
  const {
    children,
    bg = false,
    white = false,
    restricted = false,
    fixed = false,
    demoMode,
    connectionError,
  } = props;
  const { locale, userId, user } = props;
  const dispatch = useAppDispatch();
  // const { dragId } = useSortableContext();

  useEffect(() => {
    // get App settings on mount
    dispatch(fetchAppSettingsAsync());
  }, [dispatch]);

  useLayoutEffect(() => {
    // check for token on every render
    const token = Cookies.get('token');
    if (token) {
      const decodedResponse: IDecodedResponse = token ? jwtDecode(token) : {};
      const uId = decodedResponse?.data?.user?.id;
      if (uId) {
        // user logged in, set userId
        if (!user || uId !== user.id) {
          dispatch(setUserId(uId));
        }
      } else dispatch(setUserId(0));
      return;
    }
    // user not logged in, check for public pages
    dispatch(setUserId(0));
    if (restricted) {
      // not a public page, redirect to /
      window.location.href = '/';
    }
  }); // run after every render

  useEffect(() => {
    if (userId && (!user || !user.id)) {
      dispatch(fetchUserAsync(userId)).then((res: any) => {
        res?.payload?.response?.data?.locale &&
          dispatch(setLocale(res.payload.response.data.locale));
      });
    }
  }, [dispatch, userId, user]);

  // useEffect(() => {
  //   function handleKeyPress(event: KeyboardEvent) {
  //     if (event.ctrlKey && event.key === 'l') {
  //       dispatch(setLocale(locale !== 'nl' ? 'nl' : 'en'));
  //     }
  //     if (IS_DEV && event.ctrlKey && event.key === 't') {
  //       window.location.href = '/test';
  //     }
  //   }
  //   document.addEventListener('keydown', handleKeyPress);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [dispatch, locale]);

  return (
    <FormContextProvider>
      <div
        className={classNames('layout', {
          white: white,
          fixed: fixed,
          demoMode: demoMode,
          // dragging: !!dragId,
        })}
      >
        <Navigation white={white} />
        {children}
        <Forms />
      </div>
    </FormContextProvider>
  );
}

const Layout = connect(mapUserStateToProps)(
  connect(mapAdminStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedLayout),
  ),
);
export default Layout;
