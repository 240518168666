import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { userForgotAsync } from 'features/user/userSlice';
import { UserLoginFormLink } from './UserLoginForm';

export default function UserForgotForm(props: FormProps) {
  const { show, setShow } = props;
  const refSubmitBtn = useRef(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    email: '',
  });
  const [hasRequested, setHasRequested] = useState<boolean>(false);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
    setHasRequested(false);
  }, [show]);
  const forms = useForms();

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (hasRequested) {
          setShow(false);
        } else
          dispatch(userForgotAsync(data)).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              // TODO catch cases 20x, whichever is returned and catch failure responses
              default:
                doFormMsg(
                  {
                    message: intl.formatMessage({ id: 'USERS.FORGOT.SUCCESS' }),
                    success: true,
                  },
                  setFormMsg,
                );
                setHasRequested(true);
                resolve();
                break;
            }
          });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg,
        );
        console.log('Request failed for unclear reason.', err);
        reject();
      }
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_form narrow"
      title={<FormattedMessage id="USERS.FORGOT.FORM_TITLE" />}
      onSubmit={handleSubmit}
      noFooter={true}
      refSubmitBtn={refSubmitBtn}
      onCancel={null}
      enableEscape
      formMsg={formMsg}
      delayClose={10000}
    >
      <Row>
        <Col sm={12} md={12} lg={12}>
          <FormattedMessage id="USERS.FORGOT.FORM_TEXT" />
        </Col>
      </Row>
      <Form.Group as={Row} controlId="Email">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.LOGIN.EMAIL" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="email"
            name="email"
            autoComplete="username email"
            value={data.email || ''}
            onChange={handleChangeData}
            required
            // pattern={EMAIL_PATTERN}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.LOGIN.EMAIL_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <br />
      <Container fluid="md">
        <Form.Group as={Row} className="form">
          <FormMessage {...formMsg} key={formMsg.key} />
          <Button
            variant="primary wide"
            ref={refSubmitBtn}
            // disabled={hasRequested}
          >
            <FormattedMessage
              id={hasRequested ? 'X.OK' : 'USERS.FORGOT.SUBMIT'}
            />
          </Button>
          {hasRequested ? null : (
            <Button
              variant="outline-secondary wide right"
              onClick={() => {
                setShow(false);
                forms.UserSignupForm.setShow(true);
              }}
              disabled={hasRequested}
            >
              <FormattedMessage id="USERS.LOGIN.NEW_USER" />
            </Button>
          )}
        </Form.Group>
      </Container>
      <Form.Group as={Row} className="form">
        <Col align={'left'} sm={12}>
          <div className="vspaced">
            <UserLoginFormLink onClick={() => setShow(false)}>
              <FormattedMessage id="USERS.FORGOT.TRY_AGAIN" />
            </UserLoginFormLink>
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

export function UserForgotFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, className, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserForgotForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={classNames('nav_item', className)}>
      {children || <FormattedMessage id="USERS.LOGIN.FORGOT" />}
    </div>
  );
}
