import { useEffect, useState } from 'react';

import DialogueView from 'components/dialogues/DialogueView';
import { navigateIn } from 'components/navigation/Navigation';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { connect } from 'react-redux';

export function UnconnectedTryOut(
  props: UserStateProps & AppSettingsStateProps,
) {
  const { userId, user, settings } = props;
  const [okay, setOkay] = useState<boolean>(false);

  useEffect(() => {
    if (!settings._initial && !settings.rights.guestTryout) navigateIn('/', 0);
    if (!okay && settings.rights.guestTryout) {
      if (user._initial && userId === 0) {
        navigateIn(`/guest/${settings.rights.tryoutCode}`, 0);
      } else if (userId !== undefined) setOkay(true);
      else setOkay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userId, settings]);

  return okay ? <DialogueView tryout /> : <div>Loading...</div>;
}

const TryOut = connect(mapUserStateToProps)(
  connect(mapAppSettingsStateToProps)(UnconnectedTryOut),
);
export default TryOut;
