import { Text, View } from '@react-pdf/renderer';
import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import PopMenu from 'components/navigation/PopMenu';
import { IBlock } from 'features/block/blockAPI';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IMessage } from 'features/message/messageAPI';
import {
  deleteMessageAsync,
  patchMessageAsync,
} from 'features/message/messageSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { getColorForId } from 'helpers/colors';
import { IS_DEV } from 'helpers/consts';
import { onSameDay, similarTime } from 'helpers/datetime';
import { ExportProps } from 'helpers/export';
import { sameId } from 'helpers/objects';
import Author, { AuthorToPdf, authorToDocx } from '../user/Author';
import LinkifyText from './Linkify';
import TextAreaInput from './TextAreaInput';
import { confirmDelete } from './confirmDelete';

import { default as pdfStyles, default as pfdStyles } from 'css/pdfStyles';
import { Paragraph } from 'docx';
import '../../css/forms.scss';
import './Message.scss';

type MiniMessageProps = {
  message: IMessage;
  extraContent?: ReactNode | ((message?: IMessage) => ReactNode);
  block: IBlock;
  blockRef?: React.RefObject<HTMLDivElement>;
  listItemId?: number;
  // setNewReplyTo: Dispatch<SetStateAction<IMessage | null>>;
  prevTime?: Date;
  prevAuthor?: number;
  stateMan: StateMan;
  showLikes: boolean;
  showColours?: boolean;
  isNew?: boolean;
};

function UnconnectedMiniMessage(
  props: MiniMessageProps &
    // PropsWithChildren &
    UserStateProps &
    DialogueStateProps
) {
  const {
    message,
    block,
    prevAuthor,
    prevTime,
    extraContent,
    isNew = false,
    // children,
  } = props;
  const { dialogue, userId, userCanEdit, userIsAdmin } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const byme =
    typeof message.author === 'object' && message.author?.id === userId;

  useEffect(() => {
    setMsg(message.content || '');
  }, [setMsg, message]);

  function submitMessage() {
    dispatch(
      patchMessageAsync({
        data: {
          ...message,
          content: msg,
          edited: true,
        },
        stateMan: props.stateMan,
      })
    ).then((response: any) => {
      switch (response.payload?.response.status) {
        case 200:
          break;
        default:
      }
      setEditing(false);
      setMsg(message.content || '');
    });
  }

  function handleChatMsgEdit() {
    message.content && setMsg(message.content);
    setEditing(true);
  }

  function handleChatMsgDelete() {
    function doDelete() {
      message.id &&
        dispatch(
          deleteMessageAsync({
            message: message,
            stateMan: props.stateMan,
          })
        ).then((response: any) => {
          switch (response.payload?.response.status) {
            case 204:
              break;
            case 500:
            default:
              console.log(response);
          }
        });
      return true;
    }
    const isModeration =
      !sameId(message.author, userId) && userCanEdit(dialogue);
    confirmDelete(message.content, isModeration, doDelete, intl);
  }

  function handleMsgChange(input: string, hasText: boolean) {
    setMsg(input);
  }

  return (
    <>
      <div
        className={classNames('msg', 'msg_mini', {
          byme: byme,
          edited: message.edited,
          same_author:
            prevAuthor &&
            sameId(message.author, prevAuthor) &&
            onSameDay(message.time as Date, prevTime),
          same_time: similarTime(message.time as Date, prevTime),
          is_new: isNew,
        })}
        data-msg={IS_DEV ? message.id : undefined}
      >
        {
          // userCanEdit(dialogue) ||
          block && !block.locked ? (
            <PopMenu hideWithOpacity={true} noPosition={true}>
              <PopMenu.Items>
                {userCanEdit(dialogue) || sameId(message.author, userId) ? (
                  <Icon
                    symbol={IconSymbol.bin}
                    size={16}
                    onClick={handleChatMsgDelete}
                    hoverVariant={IconVariant.dark}
                    hintProps={{
                      hint: intl.formatMessage({ id: 'X.DELETE' }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
                {userIsAdmin || sameId(message.author, userId) ? (
                  <Icon
                    symbol={IconSymbol.pencil}
                    size={16}
                    onClick={handleChatMsgEdit}
                    hoverVariant={IconVariant.dark}
                    hintProps={{
                      hint: intl.formatMessage({ id: 'CHAT.EDIT' }),
                      offset: { x: 0, y: 24 },
                      offsetRight: true,
                    }}
                  />
                ) : null}
              </PopMenu.Items>
            </PopMenu>
          ) : null
        }
        <div className="msg_content">
          {editing ? (
            <TextAreaInput
              onChange={handleMsgChange}
              onSubmit={submitMessage}
              onBlur={() => {
                setEditing(false);
              }}
              value={msg}
              original={message.content}
              maxrows={6}
              emojiesBtn={true}
            />
          ) : (
            <LinkifyText>{message.content}</LinkifyText>
          )}
          {extraContent ? (
            typeof extraContent === 'function' ? (
              extraContent(message)
            ) : (
              <div className="extraContent">{extraContent}</div>
            )
          ) : null}
        </div>
        <div className="msg_footer">
          <Author
            author={message.author as IUser}
            prev={prevAuthor}
            me={byme}
            color={getColorForId(
              message.author?.id as number,
              dialogue.subscribers?.map((s) => s.id as number) || []
            )}
          />
          {message.edited ? (
            <div className="msg_edited">
              <FormattedMessage id="CHAT.EDITED" />
            </div>
          ) : null}
        </div>
        {/* {children} */}
      </div>
    </>
  );
}

const MiniMessage = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedMiniMessage)
);
export default MiniMessage;

export function MiniMessageToPdf(
  props: {
    message: IMessage;
    hideAuthor: boolean;
    extraContent?: ReactNode | ((message?: IMessage) => ReactNode);
  } & ExportProps
) {
  const { dialogue, message, hideAuthor, extraContent, intl } = props;
  const authorColor = getColorForId(
    message.author?.id as number,
    dialogue.subscribers?.map((s) => s.id as number) || []
  );

  return (
    <>
      <View style={pfdStyles.message_mini}>
        <View style={pdfStyles.message_content}>
          <Text>{message.content}</Text>
          {extraContent ? (
            <Text>
              {typeof extraContent === 'function'
                ? extraContent(message)
                : extraContent}
            </Text>
          ) : null}
        </View>
        {!hideAuthor ? (
          <View style={pfdStyles.message_footer}>
            <AuthorToPdf author={message.author as IUser} color={authorColor} />
            {message.edited ? (
              <Text style={pfdStyles.message_edited}>
                {intl.formatMessage({ id: 'CHAT.EDITED' })}
              </Text>
            ) : null}
          </View>
        ) : null}
        {/* {children} */}
      </View>
    </>
  );
}

export function miniMessageToDocx(
  props: {
    message: IMessage;
    hideAuthor: boolean;
    extraContent?: ReactNode | ((message?: IMessage) => ReactNode);
  } & ExportProps
) {
  const { dialogue, message, hideAuthor, extraContent, intl } = props;
  const authorColor = getColorForId(
    message.author?.id as number,
    dialogue.subscribers?.map((s) => s.id as number) || [],
    { asHex: true }
  );
  const extra = extraContent
    ? [
        new Paragraph({
          text:
            typeof extraContent === 'function'
              ? extraContent()?.toString()
              : extraContent.toString(),
          indent: { left: 6 * 20 },
        }),
      ]
    : [];
  const author = hideAuthor
    ? []
    : [
        authorToDocx({
          author: message.author,
          color: authorColor,
          indent: true,
        }),
      ];
  return [
    ...author,
    new Paragraph({
      text: message.content,
      style: 'message',
      indent: { left: 6 * 20 },
    }),
    ...extra,
  ];
}
